import Auth from "layouts/Auth";
import React from "react";
import { connect } from "react-redux";
import { Route, Redirect, Switch, BrowserRouter } from "react-router-dom";
import AdminLayout from "layouts/Admin.js";
import { logout } from "./actions/auth-actions/actions";
import { withRouter } from "react-router-dom";


const App = props => {
    return (
        <div className={"app"}>
            <BrowserRouter>
                <Switch>
                    <Route exact path={"/"} component={Auth} />

                    <AuthRoute
                        authenticated={props.isLoggedIn}
                        path="/dashboard"
                        component={AdminLayout}
                    />

                </Switch>
            </BrowserRouter>
        </div>
    );
};
function AuthRoute({ component: Component, authenticated, ...rest }) {

    return (
        <Route
            {...rest}
            exact
            render={props =>
                authenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{ pathname: "/", state: { from: props.location } }}
                    />
                )
            }
        />
    );
}



const mapStateToProps = reduxStore => {
    return {
        isLoggedIn: reduxStore.authReducer.isLoggedIn,
        user: reduxStore.authReducer.user,
        isLoadingUser: reduxStore.authReducer.isLoadingUser
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        { logout }
    )(App)
);