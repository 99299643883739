/*
@
 This file contains the types constants
@
*/

export const FETCH_APPARTMENTS_REQUEST = " FETCH_APPARTMENTS_REQUEST ";
export const FETCH_APPARTMENTS_FAILURE = " FETCH_APPARTMENTS_FAILURE ";
export const FETCH_APPARTMENTS_SUCCESS = " FETCH_APPARTMENTS_SUCCESS ";

export const FETCH_APPARTMENT_REQUEST = " FETCH_APPARTMENT_REQUEST ";
export const FETCH_APPARTMENT_FAILURE = " FETCH_APPARTMENT_FAILURE ";
export const FETCH_APPARTMENT_SUCCESS = " FETCH_APPARTMENT_SUCCESS ";

export const FETCH_ALL_APPARTMENTS_REQUEST = " FETCH_ALL_APPARTMENTS_REQUEST ";
export const FETCH_ALL_APPARTMENTS_FAILURE = " FETCH_ALL_APPARTMENTS_FAILURE ";
export const FETCH_ALL_APPARTMENTS_SUCCESS = " FETCH_ALL_APPARTMENTS_SUCCESS ";

export const DELETE_APPARTMENT_REQUEST = " DELETE_APPARTMENT_REQUEST ";
export const DELETE_APPARTMENT_SUCCESS = " DELETE_APPARTMENT_FAILURE ";
export const DELETE_APPARTMENT_FAILURE = " DELETE_APPARTMENT_SUCCESS ";

export const UPDATE_APPARTMENT_REQUEST = " UPDATE_APPARTMENT_REQUEST ";
export const UPDATE_APPARTMENT_SUCCESS = " UPDATE_APPARTMENT_FAILURE ";
export const UPDATE_APPARTMENT_FAILURE = " UPDATE_APPARTMENT_SUCCESS ";

export const CREATE_APPARTMENT_REQUEST = " CREATE_APPARTMENT_REQUEST ";
export const CREATE_APPARTMENT_SUCCESS = " CREATE_APPARTMENT_SUCCESS ";
export const CREATE_APPARTMENT_FAILURE = " CREATE_APPARTMENT_FAILURE ";


