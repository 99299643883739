/*
@
 This file contains the requests ( services )
@
*/

import axiosInstance from "../../config/axios-instance";

function getAppartmentsRequest(projectId) {
  return axiosInstance({
    method: "get",
    url: "projects/view/appartments/" + projectId
  });
}
function getAllAppartmentsRequest(page) {
  return axiosInstance({
    method: "get",
    url: "projects/appartments/all?page=" + page
  });
}
function getAppartmentByIdRequest(id) {
  return axiosInstance({
    method: "get",
    url: "projects/view/appartment/" + id
  });
}
function deleteAppartmentRequest(id) {
  return axiosInstance({
    method: "delete",
    url: "projects/appartment/delete?appartmentId=" + id
  });
}
function createAppartmentRequest(body) {
  return axiosInstance({
    method: "post",
    data: body,
    url: "projects/appartment/add",
  });
}
function updateAppartmentRequest(body) {
  return axiosInstance({
    method: "post",
    data: body,
    url: "projects/appartment/update/",
  });
}



const AppartmentsServices = {
  getAppartmentsRequest,
  getAllAppartmentsRequest,
  createAppartmentRequest,
  deleteAppartmentRequest,
  getAppartmentByIdRequest,
  updateAppartmentRequest


};

export default AppartmentsServices;