
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
// reactstrap components
import { Container, Row } from "reactstrap";

// core components

import AuthFooter from "components/Footers/AuthFooter.js";


import Login from "views/Login";
import { useSelector } from "react-redux";

const Auth = () => {
  const isLoggedIn = useSelector((state => state.authReducer.isLoggedIn));
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);
useEffect(()=>{
  if(isLoggedIn){
    window.location="/dashboard/home"
  }
})
  

  return (
    <>
      <div className="main-content" ref={mainContent}>
        
        <div className="header bg-gradient-info py-7 py-lg-8">
          <Container>
           
          </Container>
          
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Login/>
          </Row>
        </Container>
      </div>
      <AuthFooter />
    </>
  );
};

export default Auth;
