import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// Initialize Firebase
const app = initializeApp({
    apiKey: "AIzaSyD5iLPeTe5v9kH1x7W_tNMG9Rb0adoDZHg",
    authDomain: "boyoot-94581.firebaseapp.com",
    projectId: "boyoot-94581",
    storageBucket: "boyoot-94581.appspot.com",
    messagingSenderId: "337082477952",
    appId: "1:337082477952:web:083b1183acf30735f76565",
    measurementId: "G-6X6104TMLZ"
});

// Firebase storage reference
const storage = getStorage(app);
export default storage;