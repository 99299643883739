// reactstrap components
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  FormFeedback,
  Spinner,
} from "reactstrap";
import { signin } from "../actions/auth-actions/actions";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberme, setRemember] = useState(false);
  const [isEmailValid, setValidMail] = useState();
  const [isPassValid, setValidPass] = useState();

  const [errorMessage, setErrorMessage] = useState(null);
  const error = useSelector((state) => state.authReducer.error);
  const loading = useSelector((state) => state.authReducer.isSigningIn);
  const dispatch = useDispatch();
  const changeEmail = (e) => {
    setErrorMessage(null);
    setEmail(e.target.value);
  };
  const changePassword = (e) => {
    setErrorMessage(null);
    setPassword(e.target.value);
  };
  const changeRemember = (e) => {
    setRemember(e.target.checked);
  };

  const submit = () => {
    if (isEmailValid && isPassValid) {
      const body = {
        email,
        password,
        rememberme,
      };
      dispatch(signin(body));
      setErrorMessage(error);
    }
  };
  const validatePass = (e) => {
    setValidPass(true);
    if (e.target.value.length < 6) {
      setValidPass(false);
    }
  };
  const validateEmail = (e) => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(e.target.value)) {
      setValidMail(true);
    } else {
      setValidMail(false);
    }
  };

  useEffect(() => {
    setErrorMessage(error);
  }, [error]);
  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-3">
              <img
                className="logo-img"
                alt="..."
                src={require("../assets/img/brand/logo.png").default}
              />
            </div>
            <div className="btn-wrapper text-center"></div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    onChange={(e) => {
                      validateEmail(e);
                      changeEmail(e);
                    }}
                    placeholder="Email"
                    type="email"
                    value={email}
                    valid={isEmailValid === true}
                    invalid={isEmailValid === false}
                 
                  />
                  <FormFeedback>Adresse mail non valide</FormFeedback>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    onChange={(e) => {
                      validatePass(e);
                      changePassword(e);
                    }}
                    placeholder="Mot de passe"
                    type="password"
                    value={password}
                    valid={isPassValid === true}
                    invalid={isPassValid === false}
                  />
                  <FormFeedback>
                    Le mot de passe doit contenir au moins 6 caractères
                  </FormFeedback>
                </InputGroup>
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                  onChange={changeRemember}
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Se souvenir de moi</span>
                </label>
              </div>
              <div className="text-center">
                {!loading ? (
                  errorMessage === null ? (
                    ""
                  ) : (
                    <p
                      style={{
                        width: "100%",
                        marginTop: "1.5rem",
                        marginBottom: 0,
                        fontSize: "80%",
                        color: "#fb6340",
                      }}
                    >
                      {errorMessage}
                    </p>
                  )
                ) : (
                  <div>
                    <Spinner
                      style={{
                        marginTop: "1.5rem",

                        color: "blue",
                      }}
                    />
                  </div>
                )}

                <Button
                  className="my-4"
                  color="primary"
                  type="button"
                  onClick={submit}
                >
                  Se connecter
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Mot de passe oublié?</small>
            </a>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Login;
