
// reactstrap components
import {

  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Spinner,
  Button

} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { convertDate } from "utils/date";
import { Link, useLocation } from "react-router-dom";
import { getPayments } from "actions/payments-actions/actions";
import BoyootModal from "atoms/modal";
import { deletePayment } from "actions/payments-actions/actions";
const SinglePayment = () => {
  const dispatch = useDispatch();
  const payments = useSelector(state => state.paymentsReducer.payments);
  const loading = useSelector(state => state.paymentsReducer.isLoadingPayments);
  const total = useSelector(state => state.paymentsReducer.total);
  const [currentPage, setCurrentPage] = useState(1)
  const [paymentsData, setPaymentsData] = useState([])
  const { state } = useLocation();
  useEffect(() => {
    dispatch(getPayments(state.id, currentPage))


  }, [dispatch, currentPage])
  useEffect(() => {

    setPaymentsData(payments.payments)
  }, [payments])

  const handlePagination = (e, i) => {
    setCurrentPage(i)
  }
  const deleteItem = (id) => {
    dispatch(deletePayment(id, currentPage))
  }
  const getState = (type) => {
    switch (type) {
      case "PAID":
        return <td className=" btn-tooltip"> Payé </td>;
      case "NOTPAID":
        return <td className=" btn-tooltip"> Non payé</td>;
      case "NEXTPAYMENT":
        return <td className=" btn-tooltip">A payer</td>;
      default:
        return <td > </td>;
    }
  };
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row className="align-items-center">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">

                <Row Row className="align-items-center">
                  <div className="col-sm-8">
                    <h3 className="mb-0">Paiements de {state.name}</h3>
                  </div>
                  <div className="col-sm-4">
                    <Link to={{
                      pathname: `/dashboard/add-payment/${state.id}`,
                      state: { id: state.id }
                    }}
                    >
                      <Button

                        className="my-4"
                        color="primary"
                        type="button"

                      >
                        Ajouter un paiement{" "}
                      </Button>{" "}  </Link>

                  </div>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>

                    <th scope="col">Statut</th>
                    <th scope="col">Montant</th>
                    <th scope="col">Date d'échéance</th>
                    <th scope="col">Date de paiement</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody style={{ margin: "auto auto" }} className=" align-items-center justify-content-center">
                  {!loading ?
                    (
                      Array.isArray(paymentsData) && paymentsData.length > 0 ? paymentsData.map((item, key) => {
                        return (
                          <tr key={key}>

                            <td > {getState(item.state)}</td>
                            <td>
                              {item.amount}
                            </td>
                            <td>
                              {convertDate(new Date(item.dueDate))}

                            </td>
                            <td>
                              {item.paymentDate ? convertDate(new Date(item.paymentDate)) : "------------"}
                            </td>
                            <td scope="row">
                              <Link to={{
                                pathname: `/dashboard/update-payment/${item._id}`,
                                state: { id: item._id }
                              }}
                              >
                                <Button color="primary" style={{ paddingLeft: '15px', paddingRight: '15px' }} size="sm">
                                  Modifier
                                </Button>  </Link>


                              <br />
                              <br />
                              <BoyootModal item={item.name} delete={() => { deleteItem(item._id) }} />
                            </td>

                          </tr>)

                      }) :
                        <tr>
                          <td colspan='4' style={{ textAlign: 'center' }}>Pas de paiements</td>
                        </tr>
                    ) :
                    <tr>
                      <td colspan='4' >
                        <Spinner size={10} />
                      </td>
                    </tr>

                  }
                </tbody>
              </Table>
              <CardFooter className="py-16 ">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-center mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={currentPage === 1}>
                      <PaginationLink
                        onClick={(e) => setCurrentPage(currentPage - 1)}
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Précédent</span>
                      </PaginationLink>
                    </PaginationItem>
                    {
                      [...Array(total)].map((page, i) => {
                        return <PaginationItem active={i + 1 === currentPage} key={i} >
                          <PaginationLink
                            onClick={(e) => handlePagination(e, i + 1)}
                          >
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      })
                    }
                    <PaginationItem
                      disabled={currentPage === total}
                    >
                      <PaginationLink
                        onClick={(e) => setCurrentPage(currentPage + 1)}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Suivant</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
        {/* Dark table */}

      </Container>
    </>
  );


};

export default SinglePayment;
