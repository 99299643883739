import Index from "views/Index.js";
import Users from "views/Users.js";
import Projects from "views/Projects";
import AddUser from "views/users/AddUser";
import AddProject from "views/Projects/AddProject";
import Payments from "views/Payments";
import SinglePayment from "views/Payments/SinglePayment";
import AddPayment from "views/Payments/AddPayment";
import Appartments from "views/Appartments";
import AddAppartment from "views/appartments/AddAppartment";
import UpdateProject from "views/Projects/UpdateProject";
import UpdateUser from "views/users/UpdateUser";
import UpdatePayment from "views/Payments/UpdatePayment";
import UpdateAppartment from "views/appartments/UpdateAppartment";

var routes = [
  {
    path: "/home",
    name: "Tableau de bord",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/dashboard",
  },

  {
    path: "/users",
    name: "Utilisateurs",
    icon: "ni ni-single-02 text-yellow",
    component: Users,
    layout: "/dashboard",
  },
  {
    path: "/projects",
    name: "Projets",
    icon: "ni ni-shop text-red",
    component: Projects,
    layout: "/dashboard",
  },
  {
    path: "/appartments",
    name: "Appartements",
    icon: "ni ni-shop text-red",
    component: Appartments,
    layout: "/dashboard",
  },
  {
    path: "/add-project",
    name: "Ajouter un nouveau projet",
    icon: "ni ni-money-coins text-info",
    component: AddProject,
    layout: "/dashboard",
    type: "internal",
  },
  {
    path: "/add-appartment",
    name: "Ajouter un nouvel appartement",
    icon: "ni ni-money-coins text-info",
    component: AddAppartment,
    layout: "/dashboard",
    type: "internal",
  },

  {
    path: "/add-user",
    name: "Ajouter un nouvel utilisateur",
    icon: "ni ni-money-coins text-info",
    component: AddUser,
    layout: "/dashboard",
    type: "internal",
  },
  {
    path: "/payments",
    name: "Paiements",
    icon: "ni ni-money-coins text-info",
    component: Payments,
    layout: "/dashboard",

  },
  {
    path: "/view-payment/:id",
    name: "Paiements",
    icon: "ni ni-money-coins text-info",
    component: SinglePayment,
    layout: "/dashboard",
    type: "internal",


  },
  {
    path: "/update-project/:id",
    name: "Projects",
    icon: "ni ni-money-coins text-info",
    component: UpdateProject,
    layout: "/dashboard",
    type: "internal",


  },
  {
    path: "/update-user/:id",
    name: "Utilisateurs",
    icon: "ni ni-money-coins text-info",
    component: UpdateUser,
    layout: "/dashboard",
    type: "internal",


  },
  {
    path: "/update-appartment/:id",
    name: "Appartements",
    icon: "ni ni-money-coins text-info",
    component: UpdateAppartment,
    layout: "/dashboard",
    type: "internal",


  },
  {
    path: "/add-payment/:id",
    name: "Ajout d'un paiement",
    icon: "ni ni-money-coins text-info",
    component: AddPayment,
    layout: "/dashboard",
    type: "internal",


  },
  {
    path: "/update-payment/:id",
    name: "Utilisateurs",
    icon: "ni ni-money-coins text-info",
    component: UpdatePayment,
    layout: "/dashboard",
    type: "internal",


  },


  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Register,
  //   layout: "/auth",
  // },
];
export default routes;
