import {
  FETCH_PROJECTS_FAILURE,
  FETCH_PROJECTS_REQUEST,
  FETCH_PROJECTS_SUCCESS,
  CREATE_PROJECT_REQUEST,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_FAILURE,
  FETCH_PROJECT_REQUEST,
  FETCH_PROJECT_SUCCESS,
  FETCH_PROJECT_FAILURE,
  FETCH_ASSOCIATED_PROJECTS_REQUEST,
  FETCH_ASSOCIATED_PROJECTS_SUCCESS,
  FETCH_ASSOCIATED_PROJECTS_FAILURE,
  UPDATE_PROJECT_REQUEST,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAILURE,
} from "../actions/projects-actions/types";

const intialState = {
  projects: [],
  project: null,
  isLoadingProjects: true,
  isLoadingProject: true,
  isCreatingProject: false,
  isUpdatingProject: false,
  error: "",
  total: 0,
  count: 0
};

const productsReducer = (state = intialState, action) => {
  switch (action.type) {
    case FETCH_PROJECTS_REQUEST:
      return {
        ...state,
        isLoadingProjects: true,
      };
    case FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.payload.projects,
        total: action.payload.total,
        count: action.payload.count,
        isLoadingProjects: false,
      };

    case FETCH_PROJECTS_FAILURE:
      return {
        ...state,
        isLoadingProjects: false,
      };
    case FETCH_ASSOCIATED_PROJECTS_REQUEST:
      return {
        ...state,
        isLoadingProjects: true,
      };
    case FETCH_ASSOCIATED_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.payload.projects,
        isLoadingProjects: false,
      };

    case FETCH_ASSOCIATED_PROJECTS_FAILURE:
      return {
        ...state,
        isLoadingProjects: false,
      };

    case FETCH_PROJECT_REQUEST:
      return {
        ...state,
        isLoadingProject: true,
      };
    case FETCH_PROJECT_SUCCESS:
      return {
        ...state,
        project: action.payload.project,
        isLoadingProject: false,
      };

    case FETCH_PROJECT_FAILURE:
      return {
        ...state,
        isLoadingProject: false,
      };
    case CREATE_PROJECT_REQUEST:
      return {
        ...state,
        isCreatingProject: true,
      };
    case CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        error: null,
        isCreatingProject: false,
      };
    case CREATE_PROJECT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isCreatingProject: false,
      };
    case UPDATE_PROJECT_REQUEST:
      return {
        ...state,
        isUpdatingProject: true
      };
    case UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        error: null,
        isUpdatingProject: false,
      };
    case UPDATE_PROJECT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isUpdatingProject: false,
      };

    default:
      return state;
  }
};

export default productsReducer;
