import { useDispatch } from "react-redux";
import { logout } from "../../actions/auth-actions/actions";
import { Link } from "react-router-dom";

// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
} from "reactstrap";

const AdminNavbar = (props) => {
  const dispatch = useDispatch();
  const disconnect = () => {
    dispatch(logout());
    window.location = "/";
  };
  return (
    <>
      <Navbar className='navbar-top navbar-dark' expand='md' id='navbar-main'>
        <Container fluid>
          <Link
            className='h4 mb-0 text-white text-uppercase d-none d-lg-inline-block'
            to='/'>
            {props.brandText}
          </Link>

          <Nav className='align-items-center d-none d-md-flex' navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className='pr-0' nav>
                <div className='align-items-center'>
                  <i
                    className='ni ni-single-02'
                    style={{ marginRight: "10px" }}
                  />
                  <span className='mb-0 text-sm font-weight-bold'>
                    {localStorage.getItem("username")}
                  </span>
                </div>
              </DropdownToggle>
              <DropdownMenu className='dropdown-menu-arrow' right>
                <DropdownItem className='noti-title' header tag='div'>
                  <h6 className='text-overflow m-0'>
                    Bienvenue {localStorage.getItem("username")}{" "}
                  </h6>
                </DropdownItem>

                <DropdownItem to='/dashboard/users' tag={Link}>
                  <i className='ni ni-single-02' />
                  <span>Utilisateurs</span>
                </DropdownItem>
                <DropdownItem to='/dashboard/projects' tag={Link}>
                  <i className='ni ni ni-shop' />
                  <span>Projets</span>
                </DropdownItem>
                <DropdownItem to='/dashboard/payments' tag={Link}>
                  <i className='ni ni-credit-card' />
                  <span>Paiements</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={disconnect}>
                  <i className='ni ni-user-run' />
                  <span>Se déconnecter</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
