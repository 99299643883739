

import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';

const BoyootGallery = () => {

    const fileObj = [];
    const fileArray = [];
    const [file, setFile] = useState([])


    const uploadMultipleFiles = (e) => {
        fileObj.push(e.target.files)
        for (let i = 0; i < fileObj[0].length; i++) {
            fileArray.push(URL.createObjectURL(fileObj[0][i]))
        }
        setFile(fileArray)
    }




    return (
        <form>
            <div className="form-group multi-preview">
                <Row>
                    {(file || []).map(url => (

                        <Col sm={2} >
                            <img style={{ maxHeight: "150px" }} src={url} alt="..." />
                        </Col>
                    ))}
                </Row>
            </div>

            <div className="form-group">
                <input type="file" className="form-control" onChange={uploadMultipleFiles} multiple />
            </div>
        </form >
    )
}

export default BoyootGallery;

