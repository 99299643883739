
// reactstrap components
import {

  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Spinner,
  Button

} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { deleteUser, getUsers } from "../actions/users-actions/actions"
import { convertDate } from "utils/date";
import BoyootModal from "atoms/modal";
import { Link } from "react-router-dom";
const Users = () => {
  const dispatch = useDispatch();
  const users = useSelector(state => state.usersReducer.users);
  const loading = useSelector(state => state.usersReducer.isLoadingUsers);
  const total = useSelector(state => state.usersReducer.total);
  const [currentPage, setCurrentPage] = useState(1)
  const [usersData, setUsersData] = useState([])
  useEffect(() => {
    dispatch(getUsers(currentPage))

  }, [dispatch, currentPage])
  useEffect(() => {
    setUsersData(users)
  }, [users])

  const handlePagination = (e, i) => {
    setCurrentPage(i)
  }
  const deleteItem = (id) => {
    dispatch(deleteUser(id, currentPage))
  }
  const getRole = (role) => {
    switch (role) {
      case "TENANT": return "Locataire";
      case "ADMIN": return "Administrateur";
      case "USER": return "Client"
    }
  }
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row className="align-items-center">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">

                <Row Row className="align-items-center">
                  <div className="col-sm-8">
                    <h3 className="mb-0">Utilisateurs</h3>
                  </div>
                  <div className="col-sm-4">
                    <Button
                      onClick={() => window.location = "/dashboard/add-user"}
                      className="my-4"
                      color="primary"
                      type="button"

                    >
                      Ajouter un utilisateur{" "}
                    </Button>{" "}
                  </div>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Date d'inscription</th>
                    <th scope="col">Nom et prénom</th>
                    <th scope="col">Email</th>
                    <th scope="col">Role</th>

                    <th scope="col">Dernière modification</th>
                    <th scope="col"></th>


                  </tr>
                </thead>
                <tbody style={{ margin: "auto auto" }} className=" align-items-center justify-content-center">
                  {!loading ?
                    (
                      Array.isArray(usersData) && usersData.length > 0 ? usersData.map((item, key) => {
                        return (
                          <tr key={key}>
                            <th scope="row">
                              {convertDate(new Date(item.createdAt))}
                            </th>
                            <td > {item.firstName + " " + item.lastName}</td>
                            <td>
                              {item.email}
                            </td>
                            <td>
                              {getRole(item.role)}
                            </td>

                            <th scope="row">
                              {convertDate(new Date(item.updatedAt))}
                            </th>
                            <td scope="row">
                              <Link to={{
                                pathname: `/dashboard/update-user/${item._id}`,
                                state: { id: item._id, name: item.firstName + " " + item.lastName }
                              }}
                              >
                                <Button color="primary" style={{ paddingLeft: '15px', paddingRight: '15px' }} size="sm">
                                  Modifier
                                </Button>  </Link>


                              <br />
                              <br />
                              <BoyootModal item={item.firstName + " " + item.lastName} delete={() => { deleteItem(item._id) }} />

                            </td>
                          </tr>)

                      }) :
                        <tr>
                          <td colspan='5' style={{ textAlign: 'center' }}>Pas d'utilisateurs</td>
                        </tr>
                    ) :
                    <tr>
                      <td colspan='4' >
                        <Spinner size={10} />
                      </td>
                    </tr>

                  }
                </tbody>
              </Table>
              <CardFooter className="py-16 ">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-center mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={currentPage === 1}>
                      <PaginationLink
                        onClick={(e) => setCurrentPage(currentPage - 1)}
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Précédent</span>
                      </PaginationLink>
                    </PaginationItem>
                    {
                      [...Array(total)].map((page, i) => {
                        return <PaginationItem active={i + 1 === currentPage} key={i} >
                          <PaginationLink
                            onClick={(e) => handlePagination(e, i + 1)}
                          >
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      })
                    }
                    <PaginationItem
                      disabled={currentPage === total}
                    >
                      <PaginationLink
                        onClick={(e) => setCurrentPage(currentPage + 1)}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Suivant</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
        {/* Dark table */}

      </Container>
    </>
  );


};

export default Users;
