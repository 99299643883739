import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Button,
  Spinner,
  FormFeedback,


} from "reactstrap";
import { useState, useEffect } from "react";

// core components
import UserHeader from "components/Headers/UserHeader.js";
import { useDispatch, useSelector } from "react-redux";
import { createUser } from "actions/users-actions/actions";
import Stepper from "react-stepper-horizontal/lib/Stepper";
import { getApaprtments } from "actions/appartments-actions/actions";
import { getAssociatedProjects } from "actions/projects-actions/actions";

const AddUser = () => {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [isFirstNameValid, setFirstNameValid] = useState(null);
  const [lastName, setLastName] = useState("");
  const [isLastNameValid, setLastNameValid] = useState(null);
  const [phone, setPhone] = useState();
  const [isPhoneValid, setPhoneValid] = useState(null);
  const [email, setEmail] = useState();
  const [isEmailValid, setEmailValid] = useState(null);
  const [role, setRole] = useState("USER");
  const [password, setPassword] = useState();
  const [isPasswordValid, setPasswordValid] = useState(null);
  const [project, setProject] = useState();
  const [appartment, setAppartment] = useState();
  const [totalAmount, setTotalAmount] = useState(0);
  const [paymentState, setPaymentState] = useState("NOTPAID");
  const [istotalAmountValid, setTotalAmountValid] = useState(null);
  const [body, setBody] = useState({})
  const [currentStep, setCurrentStep] = useState(0)

  const [errorMessage, setErrorMessage] = useState(null);
  const error = useSelector((state) => state.usersReducer.error);
  const projects = useSelector(state => state.projectsReducer.projects);
  const loadingProjects = useSelector(state => state.projectsReducer.isLoadingProjects);
  const appartments = useSelector(state => state.appartmentsReducer.appartments);
  const loadingَAppartments = useSelector(state => state.appartmentsReducer.isLoadingAppartments);

  const loading = useSelector(
    (state) => state.usersReducer.isCreatingUser
  );
  const [projectsData, setProjectsData] = useState([])
  const [appartmentsData, setAppartmentsData] = useState([])

  const [rentFields, setRuntFields] = useState([
    {
      id: 1,
      amount: "",
      paymentDate: "",
      state: "NOTPAID",
      paymentType: "RENT"
    }])
  const [buyFields, setBuyFields] = useState([
    {
      id: 1,
      amount: "",
      paymentDate: "",
      state: "NOTPAID",
      paymentType: "PURCHASE"

    }])
  const [projectSteps, setProjectSteps] = useState([
    {
      id: 1,
      title: "",
      steps: [{
        id: 1,
        title: "",
        date: "",
      },

      ]

    }])

  useEffect(() => {
    dispatch(getAssociatedProjects())

  }, [dispatch,])

  useEffect(() => {
    setProjectsData(projects)
  }, [projects])
  useEffect(() => {
    setAppartmentsData(appartments)
  }, [appartments])
  useEffect(() => {
    setErrorMessage(error);
    if (error == null) {
      window.location = '/dashboard/users'
    }
  }, [error]);
  const submit = async () => {

    switch (currentStep) {
      case 0:
        if (isEmailValid == null) setEmailValid(false);
        if (isFirstNameValid == null) setFirstNameValid(false);
        if (isLastNameValid == null) setLastNameValid(false);
        if (isPhoneValid == null) setPhoneValid(false);
        if (isPasswordValid == null) setPasswordValid(false);

        if (isFirstNameValid && isEmailValid && isLastNameValid && isPhoneValid && isPasswordValid) {
          const obj = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            password: password,
            role: role
          }
          setBody(obj);
          if (role === "ADMIN") {
            await dispatch(createUser(obj));
            setErrorMessage(error);
            if (errorMessage == null) {
              window.location = '/dashboard/users'
            }
          }
          else
            setCurrentStep(currentStep + 1)
        }

        break;
      case 1: {

        body.projects = [project]
        body.appartments = [appartment]
        setCurrentStep(currentStep + 1)
        break;
      }
      case 2: {
        if (role === "TENANT") {
          body.payments = rentFields;
          body.payments.forEach(obj => {
            delete obj["id"]
          })
        }
        else {
          if (istotalAmountValid == null) setTotalAmountValid(false);
          body.totalAmount = totalAmount;
          body.payments = buyFields;
          body.payments.forEach(obj => {
            delete obj["id"]
          })
        }
        setCurrentStep(currentStep + 1)
        break;

      }
      case 3: {
        setCurrentStep(currentStep + 1)
        break;
      }

      case 4: {

        body.milestones = projectSteps;
        body.milestones.forEach(obj => {
          delete obj["id"]
          obj.steps.forEach(step => {
            delete step["id"]
          })
        })

        await dispatch(createUser(body));
        setErrorMessage(error);
        if (errorMessage == null) {
          window.location = '/dashboard/users'
        }
        break;
      }
      default:

    }
  };
  const handleFirstNameChange = (e) => {
    setFirstNameValid(true);
    if (e.target.value.length < 3) {
      setFirstNameValid(false);
    }
    setFirstName(e.target.value);
  };
  const handleLastNameChange = (e) => {
    setLastNameValid(true);
    if (e.target.value.length < 3) {
      setLastNameValid(false);
    }
    setLastName(e.target.value);
  };
  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setPhoneValid(true);
    var regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
    if (value.length < 8 && !regex.test(value)) {
      setPhoneValid(false);
    }

    setPhone(e.target.value);
  };
  const handleEmailChange = (e) => {

    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(e.target.value)) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
    setEmail(e.target.value);

  }
  const handlePasswordChange = (e) => {
    setPasswordValid(true);
    if (e.target.value.length < 6) {
      setPasswordValid(false);
    }
    setPassword(e.target.value);
  };
  const handleRoleChange = (e) => {
    setRole(e.target.value)
  };
  const handleProjectsChange = (e) => {
    dispatch(getApaprtments(e.target.value))
    setProject(e.target.value)
  };
  const handleAppartmentChange = (e) => {

    setAppartment(e.target.value)
  };
  const handleRentChange = (i, e) => {
    const values = [...rentFields]
    values[i]["paymentType"] = "RENT"
    values[i][e.target.name] = e.target.value
    switch (e.target.value) {
      case "Payé": values[i]["state"] = "PAID"; break;
      case "Non payé": values[i]["state"] = "NOTPAID"; break;
      case "Prochain paiement": values[i]["state"] = "NEXTPAYMENT"; break;
    }

    setRuntFields(values)
  }
  const handleBuyChange = (i, e) => {
    const values = [...buyFields]
    values[i]["paymentType"] = "PURCHASE"

    values[i][e.target.name] = e.target.value


    setBuyFields(values)
  }
  const handleRentAdd = (id) => {
    setRuntFields([...rentFields, {
      id: id + 2, rent: "", date: ""
    }])
  }
  const handleRentDelete = (i) => {
    const values = [...rentFields]

    values.splice(i, 1)
    setRuntFields([...values])
  }
  const handleBuyAdd = (id) => {
    setBuyFields([...buyFields, {
      id: id + 2, amount: "", date: ""
    }])
  }
  const handleBuyDelete = (i) => {
    const values = [...buyFields]

    values.splice(i, 1)
    setBuyFields([...values])
  }


  const handleStepsChange = (i, e) => {
    const values = [...projectSteps]

    values[i][e.target.name] = e.target.value


    setProjectSteps(values)
  }
  const handleTotalAmountChange = (e) => {
    setTotalAmountValid(true);
    if (e.target.value.length < 0) {
      setTotalAmountValid(false);
    }
    setTotalAmount(e.target.value);
  };
  const handleAddProjectStep = (id) => {
    setProjectSteps([...projectSteps, {
      id: id + 2, title: "", steps: [{
        id: 1,
        title: "",
        date: "",
      }]
    }])
  }
  const handleAddSubStep = (projectId, id) => {
    const values = [...projectSteps]
    values[projectId].steps.push({
      id: id + 2,
      title: "",
      date: ""
    })

    setProjectSteps(values)
  }
  const handleDeleteProjectStep = (projectId, i) => {
    const values = [...projectSteps]

    values[projectId].steps.splice(i, 1)
    setProjectSteps([...values])
  }
  const handleDeleteSubStep = (i) => {
    const values = [...projectSteps]

    values.splice(i, 1)
    setProjectSteps([...values])
  }
  const handleSubStepsChange = (projectId, i, e) => {
    const values = [...projectSteps]

    values[projectId].steps[i][e.target.name] = e.target.value


    setProjectSteps(values)
  }
  const step1 = <Form role="form">
    <div className="pl-lg-4">
      <Row>
        <Col lg="3">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-product-name"
            >
              Nom *
            </label>
            <Input
              className="form-control-alternative"
              id="input-product-name"
              placeholder="Nom "
              type="text"
              value={firstName}
              invalid={isFirstNameValid === false}
              onChange={(e) => handleFirstNameChange(e)}
            />
            <FormFeedback>
              Le nom de l'utilisateur  doit contenir au moins 3
              caractères
            </FormFeedback>
          </FormGroup>
        </Col>
        <Col lg="3">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-product-name"
            >
              Prénom *
            </label>
            <Input
              className="form-control-alternative"
              id="input-product-name"
              placeholder="Prénom "
              type="text"
              value={lastName}
              invalid={isLastNameValid === false}
              onChange={(e) => handleLastNameChange(e)}
            />
            <FormFeedback>
              Le prénom de l'utilisateur  doit contenir au moins 3
              caractères
            </FormFeedback>
          </FormGroup>
        </Col>
        <Col lg="3">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-product-name"
            >
              Téléphone *
            </label>
            <Input
              className="form-control-alternative"
              id="input-product-name"
              placeholder="Téléphone "
              type="number"
              value={phone}
              invalid={isPhoneValid === false}
              onChange={(e) => handlePhoneChange(e)}
            />
            <FormFeedback>
              Le numéro de téléphone doit contenir au moins 8
              caractères
            </FormFeedback>
          </FormGroup>

        </Col>

      </Row>
      <Row>
        <Col lg="3">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-product-name"
            >
              Email *
            </label>
            <Input
              className="form-control-alternative"
              id="input-product-name"
              placeholder="Email "
              type="text"
              value={email}
              invalid={isEmailValid === false}
              onChange={(e) => handleEmailChange(e)}
            />
            <FormFeedback>
              L'email n'est pas valide
            </FormFeedback>
          </FormGroup>
        </Col>

        <Col lg="3">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-product-name"
            >
              Mot de passe *
            </label>
            <Input
              className="form-control-alternative"
              id="input-product-name"
              placeholder="Mot de passe "
              type="password"
              value={password}
              invalid={isPasswordValid === false}
              onChange={(e) => handlePasswordChange(e)}
            />
            <FormFeedback>
              Le mot de passe doit contenir au moins 6 caractères
            </FormFeedback>
          </FormGroup>
        </Col>
        <Col lg="3">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-product-name"
            >
              Role *
            </label>
            <Input type="select" name="select" id="exampleSelect" onChange={(e) => handleRoleChange(e)}>
              <option value="USER">Client</option>
              <option value="ADMIN">Administrateur</option>
              <option value="TENANT">Locataire</option>

            </Input>

          </FormGroup>
        </Col>
      </Row>


    </div>
  </Form>
  const step2 = <Form role="form">
    <div className="pl-lg-4">

      <Row>
        <Col lg="3">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-product-name"
            >
              Projet *
            </label>
            <Input type="select" multiple name="select" id="exampleSelect" value={[project]} onChange={(e) => handleProjectsChange(e)}>
              {
                !loadingProjects ? (Array.isArray(projectsData) ? projectsData.map((item, key) => {
                  return <option value={item._id} key={key}>
                    {item.name}
                  </option>
                }) :
                  <p>Pas de projets</p>) :
                  <Spinner size={10} />
              }
            </Input>

          </FormGroup>
        </Col>
        <Col lg="3">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-product-name"
            >
              Appartements *
            </label>
            <Input type="select" multiple name="select" id="exampleSelect" value={[appartment]} onChange={(e) => handleAppartmentChange(e)} >

              {
                !loadingَAppartments ? (Array.isArray(appartmentsData) ? appartmentsData.map((item, key) => {
                  return <option value={item._id} key={key}>
                    {item.name}
                  </option>
                }) :
                  <p>Pas d'appartments</p>) :
                  <Spinner size={10} />
              }

            </Input>

          </FormGroup>
        </Col>

      </Row>

    </div>
  </Form>
  const step3 = <Form role="form">
    {
      role === "TENANT" ?
        <div className="pl-lg-4">
          {
            rentFields.map((field, i) => {

              return <div key={i}>
                <Row>
                  <Col lg="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-product-name"
                      >
                        Montant *
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-product-name"
                        placeholder="Mensualité "
                        type="number"
                        name="amount"
                        value={rentFields.amount}

                        onChange={(e) => handleRentChange(i, e)}
                      />

                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-product-name"
                      >
                        Echéance *
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-product-name"
                        placeholder="Echéance "
                        type="date"
                        name="paymentDate"
                        value={rentFields.paymentDate}

                        onChange={(e) => handleRentChange(i, e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-product-name"
                      >
                        Etat de paiement *
                      </label>
                      <Input type="select" name="select" id="exampleSelect" onChange={(e) => handleRentChange(i, e)}>
                        <option>Payé</option>
                        <option>Non payé</option>
                        <option>Prochain paiement</option>

                      </Input>

                    </FormGroup>
                  </Col>
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={() => handleRentAdd(i)}
                  >
                    Ajouter{" "}</Button>
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    disabled={field.id === 1}
                    onClick={() => handleRentDelete(i)}
                  >
                    Supprimer{" "}</Button>



                </Row>

              </div>

            })
          }



        </div > : <div className="pl-lg-4">
          <Row>
            <Col lg="3">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-product-name"
                >
                  Montant total à payer *
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-product-name"
                  placeholder="Montant "
                  type="text"
                  name="amount"
                  value={totalAmount}

                  onChange={(e) => handleTotalAmountChange(e)}
                />

              </FormGroup>
            </Col>
          </Row>
          {
            buyFields.map((field, i) => {

              return <div key={i}>
                <Row>
                  <Col lg="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-product-name"
                      >
                        Tranche *
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-product-name"
                        placeholder="Tranche "
                        type="text"
                        name="amount"
                        value={buyFields.amount}

                        onChange={(e) => handleBuyChange(i, e)}
                      />

                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-product-name"
                      >
                        Echéance *
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-product-name"
                        placeholder="Mensualité "
                        type="date"
                        name="paymentDate"
                        value={buyFields.paymentDate}

                        onChange={(e) => handleBuyChange(i, e)}
                      />
                      <Button
                        className="my-4"
                        color="primary"
                        type="button"
                        onClick={() => handleBuyAdd(i)}
                      >
                        Ajouter{" "}</Button>
                      <Button
                        className="my-4"
                        color="primary"
                        type="button"
                        disabled={field.id === 1}
                        onClick={() => handleBuyDelete(i)}
                      >
                        Supprimer{" "}</Button>
                    </FormGroup>
                  </Col>

                </Row>

              </div>

            })
          }



        </div>
    }

  </Form >
  const step4 =
    <Form>
      <Row>
        <Col lg="12">
          <FormGroup>

            <Col style={{ textAlign: "center" }} lg="12">
              {
                projectSteps.map((projectStep, i) => {
                  return <div key={projectStep.id}>
                    <Row>
                      <Col lg="4">
                        <label
                          className="form-control-label"
                          htmlFor="input-project-name"
                        >
                          Etape *
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-project-name"
                          placeholder="Titre "
                          type="text"
                          value={projectStep.title}
                          name="title"
                          onChange={(e) => handleStepsChange(i, e)}
                        />
                      </Col>
                      <Col lg="6">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={() => handleAddProjectStep(i)}
                        >
                          Ajouter une étape{" "}</Button>
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          disabled={projectStep.id === 1}
                          onClick={() => handleDeleteProjectStep(i)}
                        >
                          Supprimer une étape{" "}</Button>
                      </Col> </Row>
                  </div>
                })
              }

            </Col>

          </FormGroup>
        </Col>
      </Row>
    </Form>
  const step5 =
    <Form>
      <Row>
        <Col lg="12">
          <FormGroup>

            <Col style={{ textAlign: "center" }} lg="12">
              {
                projectSteps.map((projectStep, i) => {

                  return <div key={projectStep.id}>
                    <h2 style={{ textAlign: "left" }}>
                      {projectStep.title}
                    </h2>
                    {
                      projectStep.steps.map((substep, j) => {
                        return <div key={substep.id}>

                          <Row>
                            <Col lg="4">
                              <label
                                className="form-control-label"
                                htmlFor="input-project-name"
                              >
                                Sous-Etape *
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-project-name"
                                placeholder="Titre "
                                type="text"
                                value={substep.title}
                                name="title"
                                onChange={(e) => handleSubStepsChange(i, j, e)}
                              />
                            </Col>
                            <Col lg="4">
                              <label
                                className="form-control-label"
                                htmlFor="input-project-name"
                              >
                                Date *
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-project-name"
                                placeholder="Titre "
                                type="date"
                                value={substep.date}
                                name="date"
                                onChange={(e) => handleSubStepsChange(i, j, e)}
                              />
                            </Col>
                            <Col lg="6">
                              <Button
                                className="my-4"
                                color="primary"
                                type="button"
                                onClick={() => handleAddSubStep(i, j)}
                              >
                                Ajouter une étape{" "}</Button>
                              <Button
                                className="my-4"
                                color="primary"
                                type="button"
                                disabled={substep.id === 1}
                                onClick={() => handleDeleteSubStep(i, j)}
                              >
                                Supprimer une sous-étape{" "}</Button>
                            </Col> </Row>
                        </div>
                      })
                    }
                    <hr style={{ border: "solid 2px" }}>
                    </hr>





                  </div>
                })
              }

            </Col>

          </FormGroup>
        </Col>
      </Row>
    </Form>
  const steps = [step1, step2, step3, step4, step5]

  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>

        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Stepper steps={role === "ADMIN" ? [{ title: 'Informations de l\'utilisateur' }] : [{ title: 'Informations de l\'utilisateur' }, { title: 'Affectation au projet' }, { title: 'Paiement et modalités' }, { title: 'Etapes du projet en cours' }, { title: 'Sous-Etapes du projet en cours' }]} activeStep={currentStep} />
                <Row className="align-items-center" style={{ marginTop: "40px" }}>
                  <Col xs="8">
                    <h3 className="mb-0">Ajout d'un nouvel utilisateur</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {steps[currentStep]}
                <Row>
                  <Col>
                    {
                      currentStep > 0 ?
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={() => setCurrentStep(currentStep - 1)}
                        >
                          Précédent{" "}
                        </Button> : ""
                    }

                    <Button
                      className="my-4"
                      color="primary"
                      type="button"
                      onClick={submit}
                    >
                      {currentStep != steps.length - 1 ? "Suivant" : "Envoyer"}                    </Button>
                    {!loading ? (
                      errorMessage === null ? (
                        ""
                      ) : (
                        <p
                          style={{
                            width: "100%",
                            marginTop: "1.5rem",
                            marginBottom: 0,
                            fontSize: "80%",
                            color: "#fb6340",
                          }}
                        >
                          {errorMessage}
                        </p>
                      )
                    ) : (
                      <div>
                        <Spinner
                          style={{
                            marginTop: "1.5rem",

                            color: "blue",
                          }}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddUser;
