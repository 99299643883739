/*
@
 This file contains the actions creators
@
*/

import {
    SIGNIN_FAILURE,
    SIGNIN_REQUEST,
    SIGNIN_SUCCESS,
    GET_AUTH_SUCCESS,
    GET_AUTH_FAILURE,
    GET_AUTH_REQUEST,
    LOGOUT_REQUEST,
    CONNECT_THE_USER,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE
} from "./types";

import AuthServices from "./service";

export function getAuthUser() {
    return async dispatch => {
        await dispatch({
            type: GET_AUTH_REQUEST
        });
        try {
            const response = await AuthServices.getAuthUserRequest();

            await dispatch({
                type: GET_AUTH_SUCCESS,
                payload: {
                    user: response,
                    isLoggedIn: true,
                    status: response.status
                }
            });
        } catch (e) {

            dispatch({
                type: GET_AUTH_FAILURE,
                payload: {
                    error: e
                }
            });
        }
    };
}

export function signin(values) {
    return async dispatch => {
        dispatch({ type: SIGNIN_REQUEST });
        try {
            const response = await AuthServices.signinRequest(values);
            dispatch({ type: SIGNIN_SUCCESS, payload: response, status: response.status });
            localStorage.setItem("andromedia_token", response.data.token);
            localStorage.setItem("username", response.data.fullName);
            localStorage.setItem("role", response.data.role);
        } catch (e) {

            dispatch({
                type: SIGNIN_FAILURE, payload: {
                    error: e.response.data
                }
            });
        }
    };
}

export function logout() {
    return async dispatch => {
        dispatch({ type: LOGOUT_REQUEST });
        try {

            localStorage.removeItem("andromedia_token");
            localStorage.removeItem("username");
            dispatch({ type: LOGOUT_SUCCESS });
        } catch (e) {
            dispatch({ type: LOGOUT_FAILURE });
        }
    };
}

export function connectTheUser(token) {
    return async dispatch => {

        localStorage.setItem("andromedia_token", token);

        dispatch({
            type: CONNECT_THE_USER,
            payload: {
                token: token
            }
        });
    };
}