import {
    FETCH_USERS_FAILURE,
    FETCH_USERS_REQUEST,
    FETCH_USERS_SUCCESS,
    FETCH_USER_FAILURE,
    FETCH_USER_REQUEST,
    FETCH_USER_SUCCESS,
    CREATE_USER_REQUEST,
    CREATE_USER_SUCCESS,
    CREATE_USER_FAILURE,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,

} from "../actions/users-actions/types";

const intialState = {
    users: [],
    user: {},
    isLoadingUsers: true,
    isLoadingUser: true,
    total: 0,
    error: "",
    count: 0,
    isCreatingUser: false,
    isUpdatingUser: false
};

const usersReducer = (state = intialState, action) => {
    switch (action.type) {
        case FETCH_USERS_REQUEST:
            return {
                ...state,
                isLoadingUsers: true
            };
        case FETCH_USERS_SUCCESS:
            return {
                ...state,
                users: action.payload.users,
                total: action.payload.total,
                count: action.payload.count,
                isLoadingUsers: false
            };

        case FETCH_USERS_FAILURE:
            return {
                ...state,
                isLoadingUsers: false,

            };
        case FETCH_USER_REQUEST:
            return {
                ...state,
                isLoadingUser: true
            };
        case FETCH_USER_SUCCESS:
            return {
                ...state,
                user: action.payload.user,
                isLoadingUser: false
            };

        case FETCH_USER_FAILURE:
            return {
                ...state,
                isLoadingUser: false,

            };
        case CREATE_USER_REQUEST:
            return {
                ...state,
                isCreatingUser: true,
            };
        case CREATE_USER_SUCCESS:
            return {
                ...state,
                error: null,
                isCreatingUser: false,
            };
        case CREATE_USER_FAILURE:
            return {
                ...state,
                error: action.payload.error,
                isCreatingUser: false,
            };
        case UPDATE_USER_REQUEST:
            return {
                ...state,
                isUpdatingUser: true
            };
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                error: null,
                isUpdatingUser: false,
            };
        case UPDATE_USER_FAILURE:
            return {
                ...state,
                error: action.payload.error,
                isUpdatingUser: false,
            };

        default:
            return state;
    }
};

export default usersReducer;