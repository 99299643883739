/*
@
 This file contains the requests ( services )
@
*/

import axiosInstance from "../../config/axios-instance";





function getPaymentsByUserIdRequest(id, page) {
    return axiosInstance({
        method: "get",
        url: `/payments/user/${id}?page=` + page
    });
}
function getPaymentByIdRequest(id) {
    return axiosInstance({
        method: "get",
        url: `/payments/${id}`
    });
}
function deletePaymentRequest(id) {
    return axiosInstance({
        method: "delete",
        url: "payments/delete?paymentId=" + id
    });
}
function createPaymentRequest(body, userId) {
    return axiosInstance({
        method: "post",
        data: body,
        url: "payments/add/" + userId,
    });
}
function updatePaymentRequest(body, id) {
    return axiosInstance({
        method: "post",
        data: body,
        url: "payments/update/" + id,
    });
}
const PaymentsServices = {
    getPaymentsRequest: getPaymentsByUserIdRequest,
    getPaymentByIdRequest,
    createPaymentRequest,
    deletePaymentRequest,
    updatePaymentRequest
};

export default PaymentsServices;