// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import { useEffect, useState } from "react";
import { getUsers } from "actions/users-actions/actions";
import { getProjects } from "actions/projects-actions/actions";
import { getApaprtments } from "actions/appartments-actions/actions";
import { getAllAppartments } from "actions/appartments-actions/actions";

const Header = () => {
  const dispatch = useDispatch();
  const users = useSelector(state => state.usersReducer.count);
  const projects = useSelector(state => state.projectsReducer.count);
  const appartments = useSelector(state => state.appartmentsReducer.count);

  const [usersCount, setUsersCount] = useState(0)
  const [projectsCount, setProjectsCount] = useState(0)
  const [appartmentsCount, setAppartmentsCount] = useState(0)
  useEffect(() => {
    dispatch(getUsers())
    setUsersCount(users)
  }, [dispatch, users])
  useEffect(() => {
    dispatch(getProjects())
    setProjectsCount(projects)
  }, [dispatch, projects])
  useEffect(() => {
    dispatch(getAllAppartments())
    setAppartmentsCount(appartments)
  }, [dispatch, appartments])
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col lg="4" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Nombre de projets
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {projectsCount}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">

                          <i className="fas fa-chart-pie" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-success mr-2">

                      </span>{" "}

                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Nombre d'appartements
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{appartmentsCount}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="fas fa-building" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-danger mr-2">

                      </span>{" "}

                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Nombre d'Utilisateurs
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{usersCount}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="fas fa-users" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-danger mr-2">

                      </span>{" "}

                    </p>
                  </CardBody>
                </Card>
              </Col>


            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
