/*
@
 This file contains the actions creators
@
*/
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import storage from "../../firebaseConfig"

import {
  FETCH_PROJECTS_REQUEST,
  FETCH_PROJECTS_FAILURE,
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECT_REQUEST,
  FETCH_PROJECT_SUCCESS,
  FETCH_PROJECT_FAILURE,
  CREATE_PROJECT_REQUEST,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_FAILURE,
  DELETE_PROJECT_REQUEST,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILURE,
  FETCH_ASSOCIATED_PROJECTS_SUCCESS,
  FETCH_ASSOCIATED_PROJECTS_FAILURE,
  FETCH_ASSOCIATED_PROJECTS_REQUEST,
  UPDATE_PROJECT_REQUEST,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAILURE,

} from "./types";


import ProjectsServices from "./service";

export function getProjects(page) {
  return async dispatch => {
    await dispatch({
      type: FETCH_PROJECTS_REQUEST
    });
    try {
      const response = await ProjectsServices.getProjectsRequest(page);

      await dispatch({
        type: FETCH_PROJECTS_SUCCESS,
        payload: {
          projects: response.data.projects,
          total: response.data.total, count: response.data.count

        }
      });
    } catch (e) {
      dispatch({
        type: FETCH_PROJECTS_FAILURE
      });
    }
  };
}
export function getAssociatedProjects() {
  return async dispatch => {
    await dispatch({
      type: FETCH_ASSOCIATED_PROJECTS_REQUEST
    });
    try {
      const response = await ProjectsServices.getAssociatedProjectsRequest();

      await dispatch({
        type: FETCH_ASSOCIATED_PROJECTS_SUCCESS,
        payload: {
          projects: response.data,
        }
      });
    } catch (e) {
      dispatch({
        type: FETCH_ASSOCIATED_PROJECTS_FAILURE
      });
    }
  };
}

export function getProject(id) {
  return async dispatch => {
    await dispatch({
      type: FETCH_PROJECT_REQUEST
    });
    try {
      const response = await ProjectsServices.getProjectRequest(id);

      await dispatch({
        type: FETCH_PROJECT_SUCCESS,
        payload: {
          project: response.data
        }
      });
    } catch (e) {
      dispatch({
        type: FETCH_PROJECT_FAILURE
      });
    }
  };
}
const sendFile = async (body) => {
  //Upload project image
  const file = body.image[0][0]
  console.log(file)
  delete body.image
  const storageRef = ref(storage, `/${body.name}/${file.name}`)
  const uploadTask = uploadBytesResumable(storageRef, file);

  await uploadTask.on(
    "state_changed",
    (snapshot) => {
      const percent = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
    },
    (err) => console.log(err),
    () => {
      // download url
      getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
        console.log(url);
        body.imageUrl = url
      });
    }
  );
  //Upload appartment images
  for (let i = 0; i < body.appartments.length; i++) {

    const appartmentFile = body.appartments[i].files
    console.log(appartmentFile)
    for (let j = 0; j < appartmentFile.length; j++) {

      const f = appartmentFile[j]

      for (let k = 0; k < f.length; k++) {

        const storageRef = ref(storage, `/${body.name}/appartments/${body.appartments[i].name}/${f[k].name}`)
        const uploadTask = uploadBytesResumable(storageRef, f[k]);

        await uploadTask.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
          },
          (err) => console.log(err),
          () => {
            // download url
            getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
              console.log(url);
              if (k == 0) {
                body.appartments[i].imageUrl = url
              }
              body.appartments[i].images.push(url);

            });
          }
        );
      }
    }
  }
  delete body.appartments.files;
}

export function createProject(body) {
  return async (dispatch) => {
    await dispatch({
      type: CREATE_PROJECT_REQUEST,
    });
    try {
      await sendFile(body)

      await ProjectsServices.createProjectRequest(body);
      await dispatch({
        type: CREATE_PROJECT_SUCCESS,

      });
    } catch (e) {
      dispatch({
        type: CREATE_PROJECT_FAILURE,
        payload: {
          error: e.status,
        },
      });
    }
  };
}
export function updateProject(body) {
  return async dispatch => {
    await dispatch({
      type: UPDATE_PROJECT_REQUEST
    });
    try {
      await ProjectsServices.updateProjectRequest(body);

      await dispatch({
        type: UPDATE_PROJECT_SUCCESS,

      });

    } catch (e) {

      dispatch({
        type: UPDATE_PROJECT_FAILURE,
        payload: {
          error: e.response.data,
        },
      });
    }
  };
}
export function deleteProject(id, page) {
  return async dispatch => {
    await dispatch({
      type: DELETE_PROJECT_REQUEST
    });
    try {
      await ProjectsServices.deleteProjectRequest(id);

      await dispatch({
        type: DELETE_PROJECT_SUCCESS,

      });
      await dispatch(getProjects(page))
    } catch (e) {

      dispatch({
        type: DELETE_PROJECT_FAILURE
      });
    }
  };
}


