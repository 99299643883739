/*
@
 This file contains the requests ( services )
@
*/

import axiosInstance from "../../config/axios-instance";

function logoutRequest() {
    return axiosInstance({
        method: "post",
        url: "auth/logout",

    });
}

function signinRequest(body) {
    return axiosInstance({
        method: "post",
        url: "auth/login",
        data: body
    });
}



function getAuthUserRequest() {
    return axiosInstance({
        method: "post",
        url: "auth/validate"
    });
}

const AuthServices = {
    signinRequest,
    logoutRequest,
    getAuthUserRequest
};

export default AuthServices;