import { combineReducers } from "redux";
import authReducer from "./auth-reducer";
import paymentsReducer from "./payments-reducer";
import projectsReducer from "./projects-reducer";
import usersReducer from "./users-reducer";
import appartmentsReducer from "./appartments-reducer";


const rootReducer = combineReducers({
    authReducer,
    paymentsReducer,
    usersReducer,
    projectsReducer,
    appartmentsReducer

});

export default rootReducer;