/*
@
 This file contains the actions creators
@
*/

import {
    FETCH_PAYMENTS_REQUEST,
    FETCH_PAYMENTS_FAILURE,
    FETCH_PAYMENTS_SUCCESS,

    CREATE_PAYMENT_REQUEST,
    CREATE_PAYMENT_FAILURE,
    CREATE_PAYMENT_SUCCESS,

    FETCH_PAYMENT_REQUEST,
    FETCH_PAYMENT_FAILURE,
    FETCH_PAYMENT_SUCCESS,

    DELETE_PAYMENT_REQUEST,
    DELETE_PAYMENT_SUCCESS,
    DELETE_PAYMENT_FAILURE,
    UPDATE_PAYMENT_REQUEST,
    UPDATE_PAYMENT_SUCCESS,
    UPDATE_PAYMENT_FAILURE,

} from "./types";

import PaymentsServices from "./service";

export function getPayments(id, page) {
    return async dispatch => {
        await dispatch({
            type: FETCH_PAYMENTS_REQUEST
        });
        try {
            const response = await PaymentsServices.getPaymentsRequest(id, page);
            await dispatch({
                type: FETCH_PAYMENTS_SUCCESS,
                payload: {
                    payments: response.data.payments,
                    total: response.data.total

                }
            });
        } catch (e) {
            dispatch({
                type: FETCH_PAYMENTS_FAILURE
            });
        }
    };
}
export function getPayment(id) {
    return async dispatch => {
        await dispatch({
            type: FETCH_PAYMENT_REQUEST
        });
        try {
            const response = await PaymentsServices.getPaymentByIdRequest(id);
            await dispatch({
                type: FETCH_PAYMENT_SUCCESS,
                payload: {
                    payment: response.data,
                }
            });
        } catch (e) {
            dispatch({
                type: FETCH_PAYMENT_FAILURE
            });
        }
    };
}
export function deletePayment(id, page) {
    return async dispatch => {
        await dispatch({
            type: DELETE_PAYMENT_REQUEST
        });
        try {
            await PaymentsServices.deletePaymentRequest(id);

            await dispatch({
                type: DELETE_PAYMENT_SUCCESS,

            });
            await dispatch(getPayments(page))
        } catch (e) {

            dispatch({
                type: DELETE_PAYMENT_FAILURE
            });
        }
    };
}
export function updatePayment(body, id) {
    return async dispatch => {
        await dispatch({
            type: UPDATE_PAYMENT_REQUEST
        });
        try {
            await PaymentsServices.updatePaymentRequest(body, id);

            await dispatch({
                type: UPDATE_PAYMENT_SUCCESS,

            });

        } catch (e) {

            dispatch({
                type: UPDATE_PAYMENT_FAILURE,
                payload: {
                    error: e.response.data,
                },
            });
        }
    };
}
export function createPayment(body, userId) {
    return async (dispatch) => {
        await dispatch({
            type: CREATE_PAYMENT_REQUEST,
        });
        try {
            await PaymentsServices.createPaymentRequest(body, userId);
            await dispatch({
                type: CREATE_PAYMENT_SUCCESS,

            });
        } catch (e) {

            dispatch({
                type: CREATE_PAYMENT_FAILURE,
                payload: {
                    error: e.response.data,
                },
            });
        }
    };


}


