import {
  FETCH_APPARTMENTS_FAILURE,
  FETCH_APPARTMENTS_REQUEST,
  FETCH_APPARTMENTS_SUCCESS,
  FETCH_ALL_APPARTMENTS_FAILURE,
  FETCH_ALL_APPARTMENTS_REQUEST,
  FETCH_ALL_APPARTMENTS_SUCCESS,
  CREATE_APPARTMENT_REQUEST,
  CREATE_APPARTMENT_SUCCESS,
  CREATE_APPARTMENT_FAILURE,
  DELETE_APPARTMENT_REQUEST,
  DELETE_APPARTMENT_SUCCESS,
  DELETE_APPARTMENT_FAILURE,
  FETCH_APPARTMENT_REQUEST,
  FETCH_APPARTMENT_SUCCESS,
  FETCH_APPARTMENT_FAILURE,
  UPDATE_APPARTMENT_REQUEST,
  UPDATE_APPARTMENT_SUCCESS,
  UPDATE_APPARTMENT_FAILURE,

} from "../actions/appartments-actions/types";

const intialState = {
  appartments: [],
  allAppartments: [],
  isLoadingAppartments: true,
  isLoadingAppartment: true,
  isDeletingAppartment: true,
  isCreatingAppartment: false,
  isLoadingAllAppartments: true,
  isUpdatingAppartment: false,
  totalAllAppartments: 0,
  error: "",

};

const appartmentsReducer = (state = intialState, action) => {
  switch (action.type) {
    case FETCH_APPARTMENTS_REQUEST:
      return {
        ...state,
        isLoadingAppartments: true,
      };
    case FETCH_APPARTMENTS_SUCCESS:
      return {
        ...state,
        appartments: action.payload.appartments,

        isLoadingAppartments: false,
      };

    case FETCH_APPARTMENTS_FAILURE:
      return {
        ...state,
        isLoadingAppartments: false,
      };
    case FETCH_ALL_APPARTMENTS_REQUEST:
      return {
        ...state,
        isLoadingAllAppartments: true,
      };
    case FETCH_ALL_APPARTMENTS_SUCCESS:
      return {
        ...state,
        allAppartments: action.payload.appartments,
        totalAllAppartments: action.payload.total,
        count: action.payload.count,
        isLoadingAllAppartments: false,
      };

    case FETCH_ALL_APPARTMENTS_FAILURE:
      return {
        ...state,
        isLoadingAllAppartments: false,
      };
    case FETCH_APPARTMENT_REQUEST:
      return {
        ...state,
        isLoadingAppartment: true,
      };
    case FETCH_APPARTMENT_SUCCESS:
      return {
        ...state,
        appartment: action.payload.appartment,
        isLoadingAppartment: false,
      };

    case FETCH_APPARTMENT_FAILURE:
      return {
        ...state,
        isLoadingAppartment: false,
      };
    case CREATE_APPARTMENT_REQUEST:
      return {
        ...state,
        isCreatingAppartment: true,
      };
    case CREATE_APPARTMENT_SUCCESS:
      return {
        ...state,
        error: null,
        isCreatingAppartment: false,
      };
    case CREATE_APPARTMENT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isCreatingAppartment: false,
      };
    case DELETE_APPARTMENT_REQUEST:
      return {
        ...state,
        isDeletingAppartment: true,
      };
    case DELETE_APPARTMENT_SUCCESS:
      return {
        ...state,
        isDeletingAppartment: false,
      };

    case DELETE_APPARTMENT_FAILURE:
      return {
        ...state,
        isDeletingAppartment: false,
      };
    case UPDATE_APPARTMENT_REQUEST:
      return {
        ...state,
        isUpdatingAppartment: true
      };
    case UPDATE_APPARTMENT_SUCCESS:
      return {
        ...state,
        error: null,
        isUpdatingAppartment: false,
      };
    case UPDATE_APPARTMENT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isUpdatingAppartment: false,
      };
    default:
      return state;

  };
}
export default appartmentsReducer;
