import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useState } from "react"

const BoyootModal = (props) => {


    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const deleteItem = () => {
        toggle()
        props.delete()
    }
    const closeBtn = (
        <button className="close" onClick={toggle} type="button">
            &times;
        </button>
    );

    return (
        <div>
            <Button color="danger" size="sm" onClick={toggle}>
                Supprimer
            </Button>
            <Modal size="sm" isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle} close={closeBtn}>
                    Suppression
                </ModalHeader>
                <ModalBody>
                    êtes-vous sûr de vouloir supprimer {props.item} ?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={deleteItem}>
                        Supprimer
                    </Button>{' '}
                    <Button color="secondary" onClick={toggle}>
                        Annuler
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}


export default BoyootModal;