import {
    FETCH_PAYMENTS_FAILURE,
    FETCH_PAYMENTS_REQUEST,
    FETCH_PAYMENTS_SUCCESS,

    FETCH_PAYMENT_FAILURE,
    FETCH_PAYMENT_REQUEST,
    FETCH_PAYMENT_SUCCESS,

    CREATE_PAYMENT_FAILURE,
    CREATE_PAYMENT_REQUEST,
    CREATE_PAYMENT_SUCCESS,
    UPDATE_PAYMENT_REQUEST,
    UPDATE_PAYMENT_SUCCESS,
    UPDATE_PAYMENT_FAILURE,

} from "../actions/payments-actions/types";

const intialState = {
    payments: [],
    payment: {},
    error: "",
    isLoadingPayments: true,
    isLoadingPayment: true,
    isCreatingPayment: false,
    isUpdatingPayment: false,
    total: 0
};

const paymentsReducer = (state = intialState, action) => {
    switch (action.type) {
        case FETCH_PAYMENTS_REQUEST:
            return {
                ...state,
                isLoadingPayments: true
            };
        case FETCH_PAYMENTS_SUCCESS:
            return {
                ...state,
                payments: action.payload.payments,
                total: action.payload.total,
                isLoadingPayments: false
            };

        case FETCH_PAYMENTS_FAILURE:
            return {
                ...state,
                isLoadingPayment: false,

            };
        case FETCH_PAYMENT_REQUEST:
            return {
                ...state,
                isLoadingPayment: true
            };
        case FETCH_PAYMENT_SUCCESS:
            return {
                ...state,
                payment: action.payload.payment,
                isLoadingPayment: false
            };

        case FETCH_PAYMENT_FAILURE:
            return {
                ...state,
                isLoadingPayment: false,

            };

        case CREATE_PAYMENT_REQUEST:
            return {
                ...state,
                isCreatingPayment: true
            };
        case CREATE_PAYMENT_SUCCESS:
            return {
                ...state,
                error: null,
                isCreatingPayment: false
            };

        case CREATE_PAYMENT_FAILURE:
            return {
                ...state,
                error: action.payload.error,
                isCreatingPayment: false,

            };
        case UPDATE_PAYMENT_REQUEST:
            return {
                ...state,
                isUpdatingPayment: true
            };
        case UPDATE_PAYMENT_SUCCESS:
            return {
                ...state,
                error: null,
                isUpdatingPayment: false
            };

        case UPDATE_PAYMENT_FAILURE:
            return {
                ...state,
                error: action.payload.error,
                isUpdatingPayment: false,

            };


        default:
            return state;
    }
};

export default paymentsReducer;