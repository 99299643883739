import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Button,
  Spinner,
  FormFeedback,

} from "reactstrap";
import { useState, useEffect } from "react";
import Stepper from "react-stepper-horizontal/lib/Stepper";

// core components
import UserHeader from "components/Headers/UserHeader.js";
import { useDispatch, useSelector } from "react-redux";
import { createProject } from "actions/projects-actions/actions";
import { useLocation } from "react-router-dom";
import { getProject } from "actions/projects-actions/actions";
import { updateProject } from "actions/projects-actions/actions";

const UpdateProject = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  const [projectName, setProjectName] = useState("");
  const [isProjectNameValid, setProjectNameValid] = useState(true);
  const [description, setDescription] = useState("");
  const [startPrice, setStartPrice] = useState();
  const [isStartPriceValid, setStartPriceValid] = useState(true);

  const [isDescriptionValid, setDescriptionValid] = useState(true);
  const [stages, setStages] = useState();
  const [isStagesValid, setStagesValid] = useState(true);
  const [showOnMobile, setShowOnMobile] = useState(false);
  const [isShowenOnMobile, setShowOnMobileValid] = useState(true);
  const [associateToUser, setAssociatedToUser] = useState(false);
  const [currentStep, setCurrentStep] = useState(0)
  const [body, setBody] = useState({})

  const [statut, setStatut] = useState("FINISHED");

  const [fields, setFields] = useState([
    {
      id: 1,
      name: "",
      stage: "",
      rooms: "",
      dimensions: "",
      options: [{
        id: 1,
        key: "",
        value: ""
      }],
      description: "",
      price: "",
      errors: {
        name: null,
        stage: null,
        rooms: null,
        dimensions: null,
        description: null,
        price: null,
      }

    }])



  const [errorMessage, setErrorMessage] = useState(null);
  const project = useSelector((state) => state.projectsReducer.project);

  const error = useSelector((state) => state.projectsReducer.error);
  const loading = useSelector(
    (state) => state.projectsReducer.isCreatingProject
  );

  useEffect(() => {
    dispatch(getProject(state.id))
  }, [dispatch]);

  useEffect(() => {
    if (project != null) {
      setDescription(project.description)
      setProjectName(project.name)
      setStages(project.stages)
      setStartPrice(project.startingPrice)
      setShowOnMobile(project.showOnMobile)
      setStatut(project.projectType)
      setAssociatedToUser(project.associatedToUser)
    }
  }, [project]);

  useEffect(() => {
    if (project != null)
      if (project.appartments) {
        if (project.appartments.length > 0) {
          const values = [];
          var id = 1
          project.appartments.map((appartment, i) => {
            var i = 1;
            var options = []
            appartment.options.map((option) => {
              var opt = {
                id: i,
                key: option.key,
                value: option.value
              }
              options.push(opt)
              i++;
            })
            var obj = {
              id: id,
              name: appartment.name,
              stage: appartment.stage,
              rooms: appartment.rooms,
              dimensions: appartment.dimensions,
              description: appartment.description,
              price: appartment.price,
              options: options,
              errors: {
                name: true,
                stage: true,
                rooms: true,
                dimensions: true,
                description: true,
                price: true,
              }
            }
            values.push(obj)
            id++;

          })
          setFields(values)
        }
      }

  }, [project]);



  useEffect(() => {
    setErrorMessage(error);
    if (error == null) {
      window.location = '/dashboard/projects'
    }
  }, [error]);
  const submit = async () => {
    switch (currentStep) {
      case 0: {

        if (isProjectNameValid == null) setProjectNameValid(false);
        if (isDescriptionValid == null) setDescriptionValid(false);
        if (isStagesValid == null) setStagesValid(false);
        if (isStartPriceValid == null) setStartPriceValid(false);

        if (isProjectNameValid && isDescriptionValid && isStagesValid && isStartPriceValid) {
          const obj = {
            name: projectName,
            description: description,
            shortDescription: description,
            showOnMobile: showOnMobile,
            stages: stages,
            startingPrice: startPrice,
            projectType: statut,
            projectId: state.id
          }
          setBody(obj)
          setCurrentStep(currentStep + 1)

        } break;
      }

      case 1: {
        setCurrentStep(currentStep + 1)
        body.appartments = fields;
        break;
      }
      case 2: {
        body.associatedToUser = associateToUser;
        if (!showOnMobile) {
          body.showOnMobile = showOnMobile
          await dispatch(updateProject(body));
          setErrorMessage(error);
          if (errorMessage == null) {
            window.location = '/dashboard/projects'
          }
        }
        else setCurrentStep(currentStep + 1)
        break;
      }
      case 3: {

        body.appartments = fields;
        body.appartments.forEach((obj) => {
          delete obj["id"]
          obj.options.forEach((option) => {
            delete option["id"]
          })
        })
        body.showOnMobile = showOnMobile
        await dispatch(updateProject(body));
        setErrorMessage(error);
        if (errorMessage == null) {
          window.location = '/dashboard/projects'
        }
        break;
      }
      default: {
        const values = [...fields]
        for (var i = 0; i < values.length; i++) {
          if (values[i].errors.name == null) {
            values[i].errors.name = false
          }
          if (values[i].errors.description == null) {
            values[i].errors.description = false
          }
          if (values[i].errors.price == null) {
            values[i].errors.price = false
          }

          if (values[i].errors.stage == null) {
            values[i].errors.stage = false
          }
          if (values[i].errors.rooms == null) {
            values[i].errors.rooms = false
          }
          if (values[i].errors.dimensions == null) {
            values[i].errors.dimensions = false
          }

        }
        setFields(values)
        var canSend = true;
        for (var i = 0; i < values.length; i++) {
          if (values[i].errors.name == false) {
            canSend = false
          }
          if (values[i].errors.description == false) {
            canSend = false
          }
          if (values[i].errors.price == false) {
            canSend = false
          }

          if (values[i].errors.stage == false) {
            canSend = false
          }
          if (values[i].errors.rooms == false) {
            canSend = false
          }
          if (values[i].errors.dimensions == false) {
            canSend = false
          }

        }
        if (canSend) {

          await dispatch(updateProject(body));
          setErrorMessage(error);
          if (errorMessage == null) {
            window.location = '/dashboard/projects'
          }
        }
      }


    }
  };
  const handleProjectNameChange = (e) => {
    setProjectNameValid(true);
    if (e.target.value.length < 6) {
      setProjectNameValid(false);
    }
    setProjectName(e.target.value);
  };
  const handleStartPriceChange = (e) => {
    setStartPriceValid(true);
    if (e.target.value.length < 0) {
      setStartPriceValid(false);
    }
    setStartPrice(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescriptionValid(true);
    if (e.target.value.length < 6) {
      setDescriptionValid(false);
    }
    setDescription(e.target.value);
  };
  const handleStagesChange = (e) => {
    setStagesValid(true);
    if (e.target.value.length < 0) {
      setStagesValid(false);
    }
    setStages(e.target.value);
  };
  const handleShowOnMobile = (e) => {

    setShowOnMobile(e.target.checked);

  }
  const handleAssociatedToUser = (e) => {
    setAssociatedToUser(e.target.checked);


  }

  const handleAppartementChange = (i, e) => {
    const values = [...fields]
    values[i].errors[e.target.name] = true
    if (e.target.value.length < 0)
      values[i].errors[e.target.name] = false

    values[i][e.target.name] = e.target.value

    console.log(values[i].errors[e.target.name])
    setFields(values)
  }
  const handleAdd = (id) => {
    setFields([...fields, {
      id: id + 2,
      name: "",
      stage: "",
      rooms: "",
      dimensions: "",
      options: [{
        id: 1,
        key: "",
        value: ""
      }],
      description: "",
      price: "",
      errors: {
        name: null,
        stage: null,
        rooms: null,
        dimensions: null,
        description: null,
        price: null,
      }

    }])
  }
  const handleDelete = (i) => {
    const values = [...fields]

    values.splice(i, 1)
    setFields([...values])
  }
  const handleStatutChange = (e) => {

    setStatut(e.target.value)
  };
  const handleOptionsChange = (i, optionId, e) => {
    const values = [...fields]
    values[i].options[optionId][e.target.name] = e.target.value



    setFields(values)
  }
  const handleOptionsAdd = (id, optionId) => {
    const values = [...fields]
    values[optionId].options.push({
      id: id + 2,
      key: "",
      value: ""
    })

    setFields(values)
  }
  const handleOptionsDelete = (i, optionId) => {
    const values = [...fields]

    values[optionId].options.splice(i, 1)
    setFields([...values])
  }
  const step1 =
    <Form role="form">
      <div className="pl-lg-4">
        <Row>
          <Col lg="3">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-project-name"
              >
                Nom *
              </label>
              <Input
                className="form-control-alternative"
                id="input-project-name"
                placeholder="Nom "
                type="text"
                value={projectName}
                invalid={isProjectNameValid === false}
                onChange={(e) => handleProjectNameChange(e)}
              />
              <FormFeedback>
                Le nom du projet  doit contenir au moins 6
                caractères
              </FormFeedback>
            </FormGroup>
          </Col>

          <Col lg="3">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-product-name"
              >
                Etages *
              </label>
              <Input
                className="form-control-alternative"
                id="input-product-name"
                placeholder="Etages "
                type="number"
                value={stages}
                invalid={isStagesValid === false}

                onChange={(e) => handleStagesChange(e)}
              />
              <FormFeedback>
                Le nombre d'étages est obligatoire
              </FormFeedback>
            </FormGroup>

          </Col>
          <Col lg="3">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-product-name"
              >
                Le prix commence à partir de *
              </label>
              <Input
                className="form-control-alternative"
                id="input-product-name"
                placeholder="Prix minimal "
                type="number"
                value={startPrice}
                invalid={isStartPriceValid === false}

                onChange={(e) => handleStartPriceChange(e)}
              />
              <FormFeedback>
                Le prix minimal est obligatoire
              </FormFeedback>
            </FormGroup>

          </Col>

        </Row>

        <Row>
          <Col lg="3">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-product-name"
              >
                Description *
              </label>
              <Input
                className="form-control-alternative"
                id="input-product-name"
                placeholder="Description "
                type="textarea"
                rows="10"
                value={description}
                invalid={isDescriptionValid === false}
                onChange={(e) => handleDescriptionChange(e)}
              />
              <FormFeedback>
                La description doit contenir au moins 6
                caractères
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-product-name"
              >
                Statut *
              </label>
              <Input type="select" name="select" id="exampleSelect" value={statut} onChange={(e) => handleStatutChange(e)}>
                <option value="FINISHED">Projet fini</option>
                <option value="UNDERCONTRUCTION">Projet en cours de construction</option>


              </Input>
            </FormGroup>

          </Col>

        </Row>
      </div>
    </Form>
  const step2 =
    <Form>
      <Row>
        <Col lg="6">
          <FormGroup>
            <Col style={{ textAlign: "center" }}>


            </Col>
            <Col style={{ textAlign: "center" }}>
              {
                fields.map((field, i) => {
                  return <div key={field.id}>
                    <label
                      className="form-control-label"
                      htmlFor="input-project-name"
                    >
                      Appartement *
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-project-name"
                      placeholder="Nom "
                      type="text"
                      value={field.name}
                      name="name"
                      invalid={field.name.length < 0}
                      onChange={(e) => handleAppartementChange(i, e)}
                    />
                    <FormFeedback>
                      Le nom de l'appartement est obligatoire
                    </FormFeedback>

                    <label
                      className="form-control-label"
                      htmlFor="input-project-name"
                    >
                      Etage *
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-project-name"
                      placeholder="Etage "
                      type="text"
                      name="stage"
                      value={field.stage}
                      onChange={(e) => handleAppartementChange(i, e)}
                    />

                    <Button
                      className="my-4"
                      color="primary"
                      type="button"
                      onClick={() => handleAdd(i)}
                    >
                      Ajouter{" "}</Button>
                    <Button
                      className="my-4"
                      color="primary"
                      type="button"
                      disabled={field.id === 1}
                      onClick={() => handleDelete(i)}
                    >
                      Supprimer{" "}</Button>
                  </div>
                })
              }

            </Col>

          </FormGroup>
        </Col>
      </Row>
    </Form>
  const step3 =
    <Form>
      <Row>
        <Col lg="3">
          <FormGroup>
            <Col style={{ textAlign: "center" }}>

              <label
                className="form-control-label"
                htmlFor="input-product-name"
              >
                Visible sur l'application mobile
              </label>
            </Col>
            <Col style={{ textAlign: "center" }}>

              <Input
                className="form-control-alternative"
                id="input-product-name"
                placeholder="Email "
                type="checkbox"
                defaultChecked={showOnMobile}
                value={showOnMobile}
                onChange={(e) => handleShowOnMobile(e)}
              />
            </Col>

          </FormGroup>
        </Col>
        <Col lg="3">
          <FormGroup>
            <Col style={{ textAlign: "center" }}>

              <label
                className="form-control-label"
                htmlFor="input-product-name"
              >
                Associé à un utilisateur?
              </label>
            </Col>
            <Col style={{ textAlign: "center" }}>

              <Input
                className="form-control-alternative"
                id="input-product-name"
                placeholder="Email "
                type="checkbox"
                defaultChecked={associateToUser}
                value={associateToUser}
                onChange={(e) => handleAssociatedToUser(e)}
              />
            </Col>

          </FormGroup>
        </Col>
      </Row>
    </Form>

  const step4 =
    <Form role="form">
      <div className="pl-lg-4">
        {
          fields.map((field, i) => {
            return <div key={field.id}>
              <label
                className="form-control-label"
                htmlFor="input-project-name"
              >
                Appartement *
              </label>
              <Input
                className="form-control-alternative"
                id="input-project-name"
                placeholder="Nom "
                type="text"
                value={field.name}
                invalid={field.errors.name === false}
                name="name"
                onChange={(e) => handleAppartementChange(i, e)}
              />
              <FormFeedback>
                Le nom de l'appartement est obligatoire
              </FormFeedback>
              <br />
              <label
                className="form-control-label"
                htmlFor="input-project-name"
              >
                Description *
              </label>
              <Input
                className="form-control-alternative"
                id="input-project-name"
                placeholder="Description "
                type="textarea"
                invalid={field.errors.description === false}

                value={field.description}
                onChange={(e) => handleAppartementChange(i, e)}
                rows={4}
                name="description"

              />
              <FormFeedback>
                La description de l'appartement est obligatoire
              </FormFeedback>
              <br />
              <label
                className="form-control-label"
                htmlFor="input-project-name"
              >
                Prix *
              </label>
              <Input
                className="form-control-alternative"
                id="input-project-name"
                placeholder="Prix "
                type="number"
                name="price"
                value={field.price}
                onChange={(e) => handleAppartementChange(i, e)}
                invalid={field.errors.price === false}

              />
              <FormFeedback>
                Le prix de l'appartement est obligatoire
              </FormFeedback>
              <br />
              <Row>
                <Col xs={6}>
                  <label
                    className="form-control-label"
                    htmlFor="input-project-name"
                  >
                    Dimensions *
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-project-name"
                    placeholder="Dimensions "
                    type="text"
                    value={field.dimensions}
                    onChange={(e) => handleAppartementChange(i, e)}
                    invalid={field.errors.dimensions === false}
                    name="dimensions"
                  />
                  <FormFeedback>
                    Les dimensions de l'appartement sont obligatoires
                  </FormFeedback>
                </Col>
                <Col xs={6}>
                  <label
                    className="form-control-label"
                    htmlFor="input-project-name"
                  >
                    Chambres *
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-project-name"
                    placeholder="Chambres "
                    type="number"
                    value={field.rooms}
                    onChange={(e) => handleAppartementChange(i, e)}
                    name="rooms"
                    invalid={field.errors.rooms === false}
                  />
                  <FormFeedback>
                    Le nombre des chambres de l'appartement est obligatoire
                  </FormFeedback>
                </Col>
              </Row>
              <br />
              <label
                className="form-control-label"
                htmlFor="input-project-name"
              >
                Etage *
              </label>
              <Input
                className="form-control-alternative"
                id="input-project-name"
                placeholder="Etage "
                type="text"
                name="stage"
                value={field.stage}
                invalid={field.errors.stage === false}

                onChange={(e) => handleAppartementChange(i, e)}
              />
              <FormFeedback>
                L'étage de l'appartement est obligatoire
              </FormFeedback>
              <br />
              <h2>
                Options de l'appartement
              </h2>
              <br />
              {
                field.options.map((option, j) => {
                  return <div key={option.id}>
                    <Row>

                      <Col xs={6}>
                        <Input
                          className="form-control-alternative"
                          id="input-project-name"
                          placeholder="Option "
                          type="text"
                          name="key"
                          value={option.key}
                          onChange={(e) => handleOptionsChange(i, j, e)}
                        />
                      </Col>
                      <Col xs={6}>
                        <Input
                          className="form-control-alternative"
                          id="input-project-name"
                          placeholder="Valeur "
                          type="text"
                          name="value"
                          value={option.value}
                          onChange={(e) => handleOptionsChange(i, j, e)}
                        />
                      </Col>
                      <Button
                        className="my-4"
                        color="primary"
                        type="button"
                        onClick={() => handleOptionsAdd(j, i)}
                      >
                        Ajouter{" "}</Button>
                      <Button
                        className="my-4"
                        color="primary"
                        type="button"
                        disabled={option.id === 1}
                        onClick={() => handleOptionsDelete(j, i)}
                      >
                        Supprimer{" "}</Button>
                    </Row>
                  </div>
                })
              }

              <hr style={{ border: "solid 2px" }}>
              </hr>
            </div>
          })
        }

        <Row>



        </Row>
      </div>
    </Form>

  const steps = [step1, step2, step3, step4]
  const getSteps = () => {
    var stepper = []

    if (showOnMobile) {
      stepper = [{ title: 'Informations du projet' }, { title: 'Appartements' }, { title: 'Visibilité sur l\'application mobile' },

      { title: 'Informations sur l\'application mobile' }]
    }
    else {
      stepper = [{ title: 'Informations du projet' }, { title: 'Appartements' }, { title: 'Visibilité sur l\'application mobile' }]
    }

    return stepper;
  }
  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Stepper steps={getSteps()}
                  activeStep={currentStep} />

                <Row className="align-items-center" style={{ marginTop: "40px" }}>
                  <Col xs="8">
                    <h3 className="mb-0">Ajout d'un nouveau projet</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {steps[currentStep]}
                <Row>
                  <Col>
                    {
                      currentStep > 0 ?
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={() => setCurrentStep(currentStep - 1)}
                        >
                          Précédent{" "}
                        </Button> : ""
                    }

                    <Button
                      className="my-4"
                      color="primary"
                      type="button"
                      onClick={submit}
                    >
                      {currentStep != steps.length - 1 ? "Suivant" : "Envoyer"}                    </Button>
                    {!loading ? (
                      errorMessage === null ? (
                        ""
                      ) : (
                        <p
                          style={{
                            width: "100%",
                            marginTop: "1.5rem",
                            marginBottom: 0,
                            fontSize: "80%",
                            color: "#fb6340",
                          }}
                        >
                          {errorMessage}
                        </p>
                      )
                    ) : (
                      <div>
                        <Spinner
                          style={{
                            marginTop: "1.5rem",

                            color: "blue",
                          }}
                        />
                      </div>
                    )}
                  </Col>
                </Row>

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UpdateProject;
