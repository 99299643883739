/*
@
 This file contains the types constants
@
*/

export const FETCH_PAYMENTS_REQUEST = " FETCH_PAYMENTS_REQUEST ";
export const FETCH_PAYMENTS_FAILURE = " FETCH_PAYMENTS_FAILURE ";
export const FETCH_PAYMENTS_SUCCESS = " FETCH_PAYMENTS_SUCCESS ";

export const CREATE_PAYMENT_REQUEST = " CREATE_PAYMENT_REQUEST ";
export const CREATE_PAYMENT_FAILURE = " CREATE_PAYMENT_FAILURE ";
export const CREATE_PAYMENT_SUCCESS = " CREATE_PAYMENT_SUCCESS ";

export const FETCH_PAYMENT_REQUEST = " FETCH_PAYMENT_REQUEST ";
export const FETCH_PAYMENT_FAILURE = " FETCH_PAYMENT_FAILURE ";
export const FETCH_PAYMENT_SUCCESS = " FETCH_PAYMENT_SUCCESS ";

export const UPDATE_PAYMENT_REQUEST = " UPDATE_PAYMENT_REQUEST ";
export const UPDATE_PAYMENT_FAILURE = " UPDATE_PAYMENT_FAILURE ";
export const UPDATE_PAYMENT_SUCCESS = " UPDATE_PAYMENT_SUCCESS ";

export const DELETE_PAYMENT_REQUEST = " DELETE_PAYMENT_REQUEST ";
export const DELETE_PAYMENT_SUCCESS = " DELETE_PAYMENT_SUCCESS ";
export const DELETE_PAYMENT_FAILURE = " DELETE_PAYMENT_FAILURE ";

