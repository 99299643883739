/*
@
 This file contains the types constants
@
*/

export const FETCH_USERS_REQUEST = " FETCH_USERS_REQUEST ";
export const FETCH_USERS_FAILURE = " FETCH_USERS_FAILURE ";
export const FETCH_USERS_SUCCESS = " FETCH_USERS_SUCCESS ";

export const FETCH_USER_REQUEST = " FETCH_USER_REQUEST ";
export const FETCH_USER_FAILURE = " FETCH_USER_FAILURE ";
export const FETCH_USER_SUCCESS = " FETCH_USER_SUCCESS ";

export const CREATE_USER_REQUEST = " CREATE_USER_REQUEST ";
export const CREATE_USER_SUCCESS = " CREATE_USER_SUCCESS ";
export const CREATE_USER_FAILURE = " CREATE_USER_FAILURE ";


export const UPDATE_USER_REQUEST = " UPDATE_USER_REQUEST ";
export const UPDATE_USER_SUCCESS = " UPDATE_USER_SUCCESS ";
export const UPDATE_USER_FAILURE = " UPDATE_USER_FAILURE ";

export const DELETE_USER_REQUEST = " DELETE_USER_REQUEST ";
export const DELETE_USER_SUCCESS = " DELETE_USER_SUCCESS ";
export const DELETE_USER_FAILURE = " DELETE_USER_FAILURE ";



