
import {

  Card,
  CardHeader,
  Container,
  Row,
  Col,
} from "reactstrap";


import Header from "components/Headers/Header.js";

const Index = () => {

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>

        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Bienvenue  {localStorage.getItem("username")}</h3>
                  </div>

                </Row>
              </CardHeader>
              
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
