/*
@
 This file contains the actions creators
@
*/

import {
    FETCH_USERS_REQUEST,
    FETCH_USERS_FAILURE,
    FETCH_USERS_SUCCESS,

    FETCH_USER_REQUEST,
    FETCH_USER_FAILURE,
    FETCH_USER_SUCCESS,

    CREATE_USER_FAILURE,
    CREATE_USER_REQUEST,
    CREATE_USER_SUCCESS,

    UPDATE_USER_REQUEST,
    UPDATE_USER_FAILURE,
    UPDATE_USER_SUCCESS,


    DELETE_USER_FAILURE,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS

} from "./types";

import UsersServices from "./service";

export function getUsers(page) {
    return async dispatch => {
        await dispatch({
            type: FETCH_USERS_REQUEST
        });
        try {
            const response = await UsersServices.getUsersRequest(page);

            await dispatch({
                type: FETCH_USERS_SUCCESS,
                payload: {
                    users: response.data.users,
                    total: response.data.total,
                    count: response.data.count

                }
            });
        } catch (e) {
            dispatch({
                type: FETCH_USERS_FAILURE
            });
        }
    };
}
export function getUser(id) {
    return async dispatch => {
        await dispatch({
            type: FETCH_USER_REQUEST
        });
        try {
            const response = await UsersServices.getUserRequest(id);

            await dispatch({
                type: FETCH_USER_SUCCESS,
                payload: {
                    user: response.data,
                }
            });
        } catch (e) {
            dispatch({
                type: FETCH_USER_FAILURE
            });
        }
    };
}
export function createUser(body) {
    return async (dispatch) => {
        await dispatch({
            type: CREATE_USER_REQUEST,
        });
        try {
            await UsersServices.createUserRequest(body);
            await dispatch({
                type: CREATE_USER_SUCCESS,

            });
        } catch (e) {

            dispatch({
                type: CREATE_USER_FAILURE,
                payload: {
                    error: e.response.data,
                },
            });
        }
    };


}
export function deleteUser(id, page) {
    return async dispatch => {
        await dispatch({
            type: DELETE_USER_REQUEST
        });
        try {
            await UsersServices.deleteUserRequest(id);

            await dispatch({
                type: DELETE_USER_SUCCESS,

            });
            await dispatch(getUsers(page))
        } catch (e) {

            dispatch({
                type: DELETE_USER_FAILURE
            });
        }
    };
}
export function updateUser(body, id) {
    return async dispatch => {
        await dispatch({
            type: UPDATE_USER_REQUEST
        });
        try {
            await UsersServices.updateUserRequest(body, id);

            await dispatch({
                type: UPDATE_USER_SUCCESS,

            });

        } catch (e) {

            dispatch({
                type: UPDATE_USER_FAILURE,
                payload: {
                    error: e.response.data,
                },
            });
        }
    };
}