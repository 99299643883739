/*
@
 This file contains the actions creators
@
*/

import {
    FETCH_APPARTMENTS_REQUEST,
    FETCH_APPARTMENTS_FAILURE,
    FETCH_APPARTMENTS_SUCCESS,
    FETCH_ALL_APPARTMENTS_REQUEST,
    FETCH_ALL_APPARTMENTS_FAILURE,
    FETCH_ALL_APPARTMENTS_SUCCESS,
    DELETE_APPARTMENT_REQUEST,
    DELETE_APPARTMENT_SUCCESS,
    DELETE_APPARTMENT_FAILURE,
    CREATE_APPARTMENT_REQUEST,
    CREATE_APPARTMENT_SUCCESS,
    CREATE_APPARTMENT_FAILURE,
    FETCH_APPARTMENT_REQUEST,
    FETCH_APPARTMENT_SUCCESS,
    FETCH_APPARTMENT_FAILURE,
    UPDATE_APPARTMENT_REQUEST,
    UPDATE_APPARTMENT_SUCCESS,
    UPDATE_APPARTMENT_FAILURE,

} from "./types";


import AppartmentsServices from "./service";

export function getApaprtments(projectId) {
    return async dispatch => {
        await dispatch({
            type: FETCH_APPARTMENTS_REQUEST
        });
        try {
            const response = await AppartmentsServices.getAppartmentsRequest(projectId);

            await dispatch({
                type: FETCH_APPARTMENTS_SUCCESS,
                payload: {
                    appartments: response.data,

                }
            });
        } catch (e) {
            dispatch({
                type: FETCH_APPARTMENTS_FAILURE
            });
        }
    };
}

export function getAllAppartments(page) {
    return async dispatch => {
        await dispatch({
            type: FETCH_ALL_APPARTMENTS_REQUEST
        });
        try {
            const response = await AppartmentsServices.getAllAppartmentsRequest(page);

            await dispatch({
                type: FETCH_ALL_APPARTMENTS_SUCCESS,
                payload: {
                    appartments: response.data.appartments,
                    total: response.data.total,
                    count: response.data.count
                }
            });
        } catch (e) {
            dispatch({
                type: FETCH_ALL_APPARTMENTS_FAILURE
            });
        }
    };
}
export function getAppartment(id) {
    return async dispatch => {
        await dispatch({
            type: FETCH_APPARTMENT_REQUEST
        });
        try {
            const response = await AppartmentsServices.getAppartmentByIdRequest(id);

            await dispatch({
                type: FETCH_APPARTMENT_SUCCESS,
                payload: {
                    appartment: response.data,

                }
            });
        } catch (e) {
            dispatch({
                type: FETCH_APPARTMENT_FAILURE
            });
        }
    };
}
export function deleteAppartment(id, page) {
    return async dispatch => {
        await dispatch({
            type: DELETE_APPARTMENT_REQUEST
        });
        try {
            await AppartmentsServices.deleteAppartmentRequest(id);

            await dispatch({
                type: DELETE_APPARTMENT_SUCCESS,

            });
            await dispatch(getAllAppartments(page))
        } catch (e) {

            dispatch({
                type: DELETE_APPARTMENT_FAILURE
            });
        }
    };
}
export function createAppartment(body) {
    return async (dispatch) => {
        await AppartmentsServices.createAppartmentRequest(body);
        await dispatch({
            type: CREATE_APPARTMENT_REQUEST,
        });
        try {
            await dispatch({
                type: CREATE_APPARTMENT_SUCCESS,

            });
        } catch (e) {
            dispatch({
                type: CREATE_APPARTMENT_FAILURE,
                payload: {
                    error: e.status,
                },
            });
        }
    };
}
export function updateAppartment(body) {
    return async dispatch => {
        await dispatch({
            type: UPDATE_APPARTMENT_REQUEST
        });
        try {
            await AppartmentsServices.updateAppartmentRequest(body);

            await dispatch({
                type: UPDATE_APPARTMENT_SUCCESS,

            });

        } catch (e) {

            dispatch({
                type: UPDATE_APPARTMENT_FAILURE,
                payload: {
                    error: e.response.data,
                },
            });
        }
    };
}




