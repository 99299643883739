import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Button,
  Spinner,
} from "reactstrap";
import { useState, useEffect } from "react";

// core components
import UserHeader from "components/Headers/UserHeader.js";
import { useDispatch, useSelector } from "react-redux";
import { createPayment } from "actions/payments-actions/actions";
import { useLocation } from "react-router-dom";

const AddPayment = () => {
  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState(null);
  const [dueDate, setDueDate] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  const [amount, setAmount] = useState();
  const [paymentState, setPaymentState] = useState("NOTPAID");
  const error = useSelector((state) => state.paymentsReducer.error);
  const loading = useSelector((state) => state.paymentsReducer.isCreatingPayment);
  const { state } = useLocation();
  const [currentStep, setCurrentStep] = useState(0)

  useEffect(() => {
    setErrorMessage(error);
    if (error == null) {
      window.location = "/dashboard/payments";
    }
  }, [error]);
  const submit = async () => {
    const body = {
      dueDate,
      state: paymentState,
      paymentDate,
      amount
    }
    dispatch(createPayment(body, state.id))
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value)
  }
  const handlePaymentStateChange = (e) => {
    setPaymentState(e.target.value)
  }
  const handleDueDateChange = (e) => {
    setDueDate(e.target.value)
  }
  const handlePaymentDateChange = (e) => {
    setPaymentDate(e.target.value)
  }
  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className='mt--7' fluid>
        <Row>
          <Col className='order-xl-1' xl='12'>
            <Card className='bg-secondary shadow'>
              <CardHeader className='bg-white border-0'>
                <Row
                  className='align-items-center'
                  style={{ marginTop: "40px" }}>
                  <Col xs='8'>
                    <h3 className='mb-0'>Ajout d'un nouveau payment</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <Form role='form'>
                      <div className='pl-lg-4'>
                        <Row>
                          <Col lg='3'>
                            <FormGroup>
                              <label
                                className='form-control-label'
                                htmlFor='input-product-name'>
                                Montant *
                              </label>
                              <Input
                                className='form-control-alternative'
                                id='input-product-name'
                                placeholder='Tranche '
                                type='number'
                                name='amount'
                                value={amount}
                                onChange={(e) => handleAmountChange(e)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg='3'>
                            <FormGroup>
                              <label
                                className='form-control-label'
                                htmlFor='input-product-name'>
                                Echéance *
                              </label>
                              <Input
                                className='form-control-alternative'
                                id='input-product-name'
                                placeholder='Echéance '
                                type='date'
                                name='deadline'
                                value={dueDate}
                                onChange={(e) => handleDueDateChange(e)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg='3'>
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-product-name"
                              >
                                Etat de paiement *
                              </label>
                              <Input type="select" name="select" id="exampleSelect" value={paymentState} onChange={(e) => handlePaymentStateChange(e)}>
                                <option value="PAID">Payé</option>
                                <option value="NOTPAID">Non payé</option>
                                <option value="NEXTPAYMENT">Prochain paiement</option>

                              </Input>

                            </FormGroup>
                          </Col>
                          {
                            paymentState === "PAID" ? <Col lg='3'>
                              <FormGroup>
                                <label
                                  className='form-control-label'
                                  htmlFor='input-product-name'>
                                  Date de paiement *
                                </label>
                                <Input
                                  className='form-control-alternative'
                                  id='input-product-name'
                                  placeholder='Echéance '
                                  type='date'
                                  name='deadline'
                                  value={paymentDate}
                                  onChange={(e) => handlePaymentDateChange(e)}
                                />
                              </FormGroup>
                            </Col> : null
                          }
                        </Row>
                      </div>
                    </Form>

                    <Button
                      className='my-4'
                      color='primary'
                      type='button'
                      onClick={submit}>
                      Envoyer              </Button>
                    {!loading ? (
                      errorMessage === null ? (
                        ""
                      ) : (
                        <p
                          style={{
                            width: "100%",
                            marginTop: "1.5rem",
                            marginBottom: 0,
                            fontSize: "80%",
                            color: "#fb6340",
                          }}>
                          {errorMessage}
                        </p>
                      )
                    ) : (
                      <div>
                        <Spinner
                          style={{
                            marginTop: "1.5rem",

                            color: "blue",
                          }}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddPayment;
