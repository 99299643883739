import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Button,
  Spinner,
  FormFeedback,

} from "reactstrap";
import { useState, useEffect } from "react";
import Stepper from "react-stepper-horizontal/lib/Stepper";

// core components
import UserHeader from "components/Headers/UserHeader.js";
import { useDispatch, useSelector } from "react-redux";
import { getProjects } from "actions/projects-actions/actions";
import { createAppartment } from "actions/appartments-actions/actions";
import { useLocation } from "react-router-dom";
import { getAppartment } from "actions/appartments-actions/actions";
import { updateAppartment } from "actions/appartments-actions/actions";

const UpdateAppartment = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  const [currentStep, setCurrentStep] = useState(0)

  const [options, setOptions] = useState([
    {
      id: 1,
      key: "",
      value: ""
    }])


  const [errorMessage, setErrorMessage] = useState(null);
  const [project, setProject] = useState("");

  const [appartmentName, setAppartmentName] = useState("");
  const [appartmentNameValid, setAppartmentNameValid] = useState(true);
  const [appartmentDescription, setAppartmentDescription] = useState("");
  const [appartmentDescriptionValid, setAppartmentDescriptionValid] = useState(true);
  const [appartmentPrice, setAppartmentPrice] = useState(0);
  const [appartmentPriceValid, setAppartmentPriceValid] = useState(true);
  const [appartmentDimensions, setAppartmentDimensions] = useState("");
  const [appartmentDimensionsValid, setAppartmentDimensionsValid] = useState(true);
  const [appartmentStage, setAppartmentStage] = useState("");
  const [appartmentStageValid, setAppartmentStageValid] = useState(true);
  const [appartmentRooms, setAppartmentRooms] = useState(0);
  const [appartmentRoomsValid, setAppartmentRoomsValid] = useState(true);
  const [projectsData, setProjectsData] = useState([])

  const error = useSelector((state) => state.appartmentsReducer.error);
  const appartment = useSelector((state) => state.appartmentsReducer.appartment);
  const projects = useSelector(state => state.projectsReducer.projects);
  const loading = useSelector(
    (state) => state.appartmentsReducer.isCreatingAppartment
  );

  useEffect(() => {
    dispatch(getAppartment(state.id))
  }, [dispatch]);

  useEffect(() => {
    if (appartment != null) {
      setAppartmentDescription(appartment.description)
      setAppartmentName(appartment.name)
      setAppartmentPrice(appartment.price)
      setAppartmentRooms(appartment.rooms)
      setAppartmentDimensions(appartment.dimensions)
      setAppartmentStage(appartment.stage)
      setProject(appartment.project)
      if (appartment.options) {
        if (appartment.options.length > 0) {
          const values = [];
          var id = 1
          appartment.options.map((option, i) => {

            var obj = {
              id: id,
              key: option.key,
              value: option.value
            }
            values.push(obj)
            id++;

          })

          setOptions(values)
        }
      }
    }
  }, [appartment]);
  useEffect(() => {
    setErrorMessage(error);
    if (error == null) {
      window.location = '/dashboard/appartments'
    }
  }, [error]);
  useEffect(() => {
    setProjectsData(projects)
  }, [projects])
  useEffect(() => {
    dispatch(getProjects())

  }, [dispatch,])

  const handleAppartmentName = (e) => {
    setAppartmentNameValid(true);
    if (e.target.value.length < 0) {
      setAppartmentNameValid(false);
    }
    setAppartmentName(e.target.value);
  };
  const handleProject = (e) => {

    setProject(e.target.value);
  };
  const handleAppartmentDescription = (e) => {
    setAppartmentDescriptionValid(true);
    if (e.target.value.length < 0) {
      setAppartmentDescriptionValid(false);
    }
    setAppartmentDescription(e.target.value);
  };
  const handleAppartmentPrice = (e) => {
    setAppartmentPriceValid(true);
    if (e.target.value.length < 0) {
      setAppartmentPriceValid(false);
    }
    setAppartmentPrice(e.target.value);
  };
  const handleAppartmentDimensions = (e) => {
    setAppartmentDimensionsValid(true);
    if (e.target.value.length < 0) {
      setAppartmentDimensionsValid(false);
    }
    setAppartmentDimensions(e.target.value);
  };
  const handleAppartmentRooms = (e) => {
    setAppartmentRoomsValid(true);
    if (e.target.value.length < 0) {
      setAppartmentRoomsValid(false);
    }
    setAppartmentRooms(e.target.value);
  };
  const handleAppartmentStage = (e) => {
    setAppartmentStageValid(true);
    if (e.target.value.length < 0) {
      setAppartmentStageValid(false);
    }
    setAppartmentStage(e.target.value);
  };
  const handleOptions = (i, e) => {
    const values = [...options]
    values[i][e.target.name] = e.target.value
    setOptions(values)
  }
  const handleAdd = (id) => {
    setOptions([...options, {
      id: id + 2, key: "", value: ""
    }])
  }
  const handleDelete = (i) => {
    const values = [...options]

    values.splice(i, 1)
    setOptions([...values])
  }
  const submit = async () => {
    if (appartmentNameValid == null) setAppartmentNameValid(false)
    if (appartmentPriceValid == null) setAppartmentPriceValid(false)
    if (appartmentDescriptionValid == null) setAppartmentDescriptionValid(false)
    if (appartmentDimensionsValid == null) setAppartmentDimensionsValid(false)
    if (appartmentRoomsValid == null) setAppartmentRoomsValid(false)
    if (appartmentStageValid == null) setAppartmentStageValid(false)

    if (appartmentPriceValid && appartmentDescriptionValid && appartmentDimensionsValid && appartmentNameValid && appartmentRoomsValid && appartmentStageValid) {
      var body = {
        name: appartmentName,
        description: appartmentDescription,
        price: appartmentPrice,
        rooms: appartmentRooms,
        stage: appartmentStage,
        projectId: project,
        dimensions: appartmentDimensions,
        options: options,
        appartmentId: appartment._id
      }
      body.options.forEach(obj => {
        delete obj["id"]
      })

      await dispatch(updateAppartment(body));
      setErrorMessage(error);
      if (errorMessage == null) {
        window.location = '/dashboard/appartments'
      }
    }
  };



  const step1 =
    <Form role="form">
      <div className="pl-lg-4">


        <label
          className="form-control-label"
          htmlFor="input-project-name"
        >
          Projet *
        </label>
        <Input
          className="form-control-alternative"
          id="input-project-name"
          placeholder="Nom "
          type="select"
          value={project}
          name="name"
          onChange={(e) => handleProject(e)}
        >
          {
            projectsData.map((item, i) => {
              return <option value={item._id}>{item.name}</option>
            })
          }
        </Input>
        <br />
        <label
          className="form-control-label"
          htmlFor="input-project-name"
        >
          Appartement *
        </label>
        <Input
          className="form-control-alternative"
          id="input-project-name"
          placeholder="Nom "
          type="text"
          value={appartmentName}
          invalid={appartmentNameValid === false}
          name="name"
          onChange={(e) => handleAppartmentName(e)}
        />
        <FormFeedback>
          Le nom de l'appartement est obligatoire
        </FormFeedback>
        <br />
        <label
          className="form-control-label"
          htmlFor="input-project-name"
        >
          Description *
        </label>
        <Input
          className="form-control-alternative"
          id="input-project-name"
          placeholder="Description "
          type="textarea"
          invalid={appartmentDescriptionValid === false}

          value={appartmentDescription}
          onChange={(e) => handleAppartmentDescription(e)}
          rows={4}
          name="desc"

        />
        <FormFeedback>
          La description de l'appartement est obligatoire
        </FormFeedback>
        <br />
        <label
          className="form-control-label"
          htmlFor="input-project-name"
        >
          Prix *
        </label>
        <Input
          className="form-control-alternative"
          id="input-project-name"
          placeholder="Prix "
          type="text"
          name=""
          value={appartmentPrice}
          onChange={(e) => handleAppartmentPrice(e)}
          invalid={appartmentPrice === false}

        />
        <FormFeedback>
          Le prix de l'appartement est obligatoire
        </FormFeedback>
        <br />
        <Row>
          <Col xs={6}>
            <label
              className="form-control-label"
              htmlFor="input-project-name"
            >
              Dimensions *
            </label>
            <Input
              className="form-control-alternative"
              id="input-project-name"
              placeholder="Dimensions "
              type="text"
              value={appartmentDimensions}
              onChange={(e) => handleAppartmentDimensions(e)}
              invalid={appartmentDimensionsValid === false}
              name=""
            />
            <FormFeedback>
              Les dimensions de l'appartement sont obligatoires
            </FormFeedback>
          </Col>
          <Col xs={6}>
            <label
              className="form-control-label"
              htmlFor="input-project-name"
            >
              Chambres *
            </label>
            <Input
              className="form-control-alternative"
              id="input-project-name"
              placeholder="Chambres "
              type="number"
              value={appartmentRooms}
              onChange={(e) => handleAppartmentRooms(e)}
              name=""
              invalid={appartmentRoomsValid === false}
            />
            <FormFeedback>
              Le nombre des chambres de l'appartement est obligatoire
            </FormFeedback>
          </Col>
        </Row>
        <br />
        <label
          className="form-control-label"
          htmlFor="input-project-name"
        >
          Etage *
        </label>
        <Input
          className="form-control-alternative"
          id="input-project-name"
          placeholder="Etage "
          type="text"
          name="stage"
          value={appartmentStage}
          invalid={appartmentStageValid === false}

          onChange={(e) => handleAppartmentStage(e)}
        />
        <FormFeedback>
          L'étage de l'appartement est obligatoire
        </FormFeedback>
        <br />
        <h2>
          Options de l'appartement
        </h2>
        <br />
        {
          options.map((option, i) => {
            return <div key={option.id}>
              <Row>

                <Col xs={6}>
                  <Input
                    className="form-control-alternative"
                    id="input-project-name"
                    placeholder="Option "
                    type="text"
                    name="key"
                    value={option.key}
                    onChange={(e) => handleOptions(i, e)}
                  />
                </Col>
                <Col xs={6}>
                  <Input
                    className="form-control-alternative"
                    id="input-project-name"
                    placeholder="Valeur "
                    type="text"
                    name="value"
                    value={option.value}
                    onChange={(e) => handleOptions(i, e)}
                  />
                </Col>
                <Button
                  className="my-4"
                  color="primary"
                  type="button"
                  onClick={() => handleAdd(i)}
                >
                  Ajouter{" "}</Button>
                <Button
                  className="my-4"
                  color="primary"
                  type="button"
                  disabled={option.id === 1}
                  onClick={() => handleDelete(i)}
                >
                  Supprimer{" "}</Button>
              </Row>
            </div>
          })
        }


      </div>

    </Form >

  const steps = [step1]

  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Stepper steps={[{ title: "Informations de l\'appartement" }]}
                  activeStep={currentStep} />

                <Row className="align-items-center" style={{ marginTop: "40px" }}>
                  <Col xs="8">
                    <h3 className="mb-0">Ajout d'un nouvel appartement</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {steps[currentStep]}
                <Row>
                  <Col>
                    {
                      currentStep > 0 ?
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={() => setCurrentStep(currentStep - 1)}
                        >
                          Précédent{" "}
                        </Button> : ""
                    }

                    <Button
                      className="my-4"
                      color="primary"
                      type="button"
                      onClick={submit}
                    >
                      {currentStep != steps.length - 1 ? "Suivant" : "Envoyer"}                    </Button>
                    {!loading ? (
                      errorMessage === null ? (
                        ""
                      ) : (
                        <p
                          style={{
                            width: "100%",
                            marginTop: "1.5rem",
                            marginBottom: 0,
                            fontSize: "80%",
                            color: "#fb6340",
                          }}
                        >
                          {errorMessage}
                        </p>
                      )
                    ) : (
                      <div>
                        <Spinner
                          style={{
                            marginTop: "1.5rem",

                            color: "blue",
                          }}
                        />
                      </div>
                    )}
                  </Col>
                </Row>

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UpdateAppartment;
