/*
@
 This file contains the types constants
@
*/

export const FETCH_PROJECTS_REQUEST = " FETCH_PROJECTS_REQUEST ";
export const FETCH_PROJECTS_FAILURE = " FETCH_PROJECTS_FAILURE ";
export const FETCH_PROJECTS_SUCCESS = " FETCH_PROJECTS_SUCCESS ";

export const FETCH_ASSOCIATED_PROJECTS_REQUEST = " FETCH_ASSOCIATED_PROJECTS_REQUEST ";
export const FETCH_ASSOCIATED_PROJECTS_FAILURE = " FETCH_ASSOCIATED_PROJECTS_FAILURE ";
export const FETCH_ASSOCIATED_PROJECTS_SUCCESS = " FETCH_ASSOCIATED_PROJECTS_SUCCESS ";

export const FETCH_PROJECT_REQUEST = " FETCH_PROJECT_REQUEST ";
export const FETCH_PROJECT_FAILURE = " FETCH_PROJECT_FAILURE ";
export const FETCH_PROJECT_SUCCESS = " FETCH_PROJECT_SUCCESS ";

export const CREATE_PROJECT_REQUEST = " CREATE_PROJECT_REQUEST ";
export const CREATE_PROJECT_SUCCESS = " CREATE_PROJECT_SUCCESS ";
export const CREATE_PROJECT_FAILURE = " CREATE_PROJECT_FAILURE ";

export const UPDATE_PROJECT_REQUEST = " UPDATE_PROJECT_REQUEST ";
export const UPDATE_PROJECT_SUCCESS = " UPDATE_PROJECT_SUCCESS ";
export const UPDATE_PROJECT_FAILURE = " UPDATE_PROJECT_FAILURE ";

export const DELETE_PROJECT_REQUEST = " DELETE_PROJECT_REQUEST ";
export const DELETE_PROJECT_SUCCESS = " DELETE_PROJECT_SUCCESS ";
export const DELETE_PROJECT_FAILURE = " DELETE_PROJECT_FAILURE ";


