/*
@
 This file contains the requests ( services )
@
*/

import axiosInstance from "../../config/axios-instance";


function getUsersRequest(page) {
    return axiosInstance({
        method: "get",
        url: "auth/users/?page=" + page
    });
}
function createUserRequest(body) {
    return axiosInstance({
        method: "post",
        data: body,
        url: "auth/register",
    });
}

function updateUserRequest(body, id) {
    return axiosInstance({
        method: "post",
        data: body,
        url: "auth/user/update/" + id,
    });
}

function getUserRequest(id) {
    return axiosInstance({
        method: "get",
        url: "auth/user/" + id,
    });
}
function deleteUserRequest(id) {
    return axiosInstance({
        method: "delete",
        url: "auth/delete?userId=" + id
    });
}
const UsersServices = {
    getUsersRequest, createUserRequest, deleteUserRequest, getUserRequest, updateUserRequest

};

export default UsersServices;