/*
@
 This file contains the requests ( services )
@
*/

import axiosInstance from "../../config/axios-instance";

function getProjectsRequest(page) {
  return axiosInstance({
    method: "get",
    url: "projects/all?page=" + page
  });
}
function getAssociatedProjectsRequest(page) {
  return axiosInstance({
    method: "get",
    url: "projects/associated/all?page=" + page
  });
}
function getProjectRequest(id) {
  return axiosInstance({
    method: "get",
    url: "projects/view/" + id
  });
}
function createProjectRequest(body) {
  return axiosInstance({
    method: "post",
    data: body,
    url: "projects/add",
  });
}
function updateProjectRequest(body) {
  return axiosInstance({
    method: "post",
    data: body,
    url: "projects/update",
  });
}
function deleteProjectRequest(id) {
  return axiosInstance({
    method: "delete",
    url: "projects/delete?projectId=" + id
  });
}
const ProjectsServices = {
  getProjectsRequest,
  createProjectRequest,
  deleteProjectRequest,
  getProjectRequest,
  getAssociatedProjectsRequest,
  updateProjectRequest
};

export default ProjectsServices;